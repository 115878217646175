import React, { useEffect, useState } from "react";

const PerseveranceParachute = (props) => {
  const [innerRing, setInnerRing] = useState(props.encodedInnerRing);
  const [secondRing, setSecondRing] = useState(props.encodedSecondRing);
  const [thirdRing, setThirdRing] = useState(props.encodedThirdRing);
  const [outerRing, setOuterRing] = useState(props.encodedOuterRing);

  useEffect(() => {
    setInnerRing(props.encodedInnerRing);
  }, [props.encodedInnerRing]);

  useEffect(() => {
    setSecondRing(props.encodedSecondRing);
  }, [props.encodedSecondRing]);

  useEffect(() => {
    setThirdRing(props.encodedThirdRing);
  }, [props.encodedThirdRing]);

  useEffect(() => {
    setOuterRing(props.encodedOuterRing);
  }, [props.encodedOuterRing]);

  return (
    <svg
      width="100%"
      height="100%"
      viewBox="0 0 5000 5000"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      xmlSpace="preserve"
      xmlnsSerif="http://www.serif.com/"
      style={{fillRule: "evenodd", clipRule: "evenodd", strokeLinecap: "round", strokeLinejoin: "round", strokeMiterlimit: "1.5"}}
      id="parachute"
    >

        <g>
            <g id="outermost-ring">
                <path d="M2675.54,274.833L2686.06,141.208C2624.32,136.375 2562.39,133.917 2500.42,133.917L2500.48,267.958C2558.88,267.958 2617.27,270.25 2675.54,274.833Z" style={{fill: `${outerRing[7].charAt(7) === "1" ? "#DF6A33" : "#FFFFFF"}`, stroke: "#787878", strokeWidth: "11.67px"}}/>

                <path d="M2674.71,274.783L2685.23,141.158C2746.96,146.042 2808.51,153.301 2869.72,162.996L2848.69,295.378C2791.01,286.243 2732.98,279.371 2674.71,274.783Z" style={{fill: "white", stroke: "#787878", strokeWidth: "11.67px"}}/>
                <path d="M3021.47,329.703L3052.76,199.368C2992.54,184.937 2931.76,172.822 2870.55,163.127L2849.64,295.527C2907.32,304.663 2964.64,316.061 3021.47,329.703Z" style={{fill: "white", stroke: "#787878", strokeWidth: "11.67px"}}/>
                <path d="M3020.67,329.495L3051.96,199.16C3112.17,213.642 3171.82,230.439 3230.77,249.591L3189.29,377.054C3133.75,359.007 3077.5,343.142 3020.67,329.495Z" style={{fill: "white", stroke: "#787878", strokeWidth: "11.67px"}}/>

                <path d="M3354.56,437.983L3405.85,314.148C3348.63,290.473 3290.5,269 3231.56,249.848L3190.19,377.348C3245.73,395.394 3300.56,415.619 3354.56,437.983Z" style={{fill: `${outerRing[0].charAt(1) === "1" ? "#DF6A33" : "#FFFFFF"}`, stroke: "#787878", strokeWidth: "11.67px"}}/>
                <path d="M3353.8,437.665L3405.09,313.83C3462.3,337.552 3518.59,363.475 3573.81,391.611L3512.9,511.016C3460.87,484.503 3407.8,460.034 3353.8,437.665Z" style={{fill: `${outerRing[0].charAt(2) === "1" ? "#DF6A33" : "#FFFFFF"}`, stroke: "#787878", strokeWidth: "11.67px"}}/>
                <path d="M3666.61,597.05L3736.64,482.764C3683.83,450.429 3629.77,420.125 3574.55,391.989L3513.75,511.448C3565.78,537.961 3616.78,566.514 3666.61,597.05Z" style={{fill: `${outerRing[0].charAt(3) === "1" ? "#DF6A33" : "#FFFFFF"}`, stroke: "#787878", strokeWidth: "11.67px"}}/>
                <path d="M3665.91,596.622L3735.95,482.336C3788.73,514.714 3840.28,549.124 3890.41,585.552L3811.58,693.959C3764.33,659.633 3715.74,627.163 3665.91,596.622Z" style={{fill: `${outerRing[0].charAt(4) === "1" ? "#DF6A33" : "#FFFFFF"}`, stroke: "#787878", strokeWidth: "11.67px"}}/>
                <path d="M3949.93,802.979L4036.98,701.056C3989.88,660.857 3941.22,622.469 3891.08,586.042L3812.34,694.518C3859.59,728.844 3905.49,765.023 3949.93,802.979Z" style={{fill: `${outerRing[0].charAt(5) === "1" ? "#DF6A33" : "#FFFFFF"}`, stroke: "#787878", strokeWidth: "11.67px"}}/>
                <path d="M3949.31,802.444L4036.36,700.52C4083.43,740.758 4128.96,782.807 4172.78,826.63L4077.96,921.371C4036.66,880.076 3993.75,840.404 3949.31,802.444Z" style={{fill: `${outerRing[0].charAt(6) === "1" ? "#DF6A33" : "#FFFFFF"}`, stroke: "#787878", strokeWidth: "11.67px"}}/>
                <path d="M4197.56,1050.69L4299.48,963.639C4259.25,916.566 4217.2,871.04 4173.37,827.217L4078.63,922.04C4119.93,963.335 4159.6,1006.25 4197.56,1050.69Z" style={{fill: `${outerRing[0].charAt(7) === "1" ? "#DF6A33" : "#FFFFFF"}`, stroke: "#787878", strokeWidth: "11.67px"}}/>

                <path d="M4197.02,1050.07L4298.94,963.019C4339.14,1010.12 4377.53,1058.78 4413.96,1108.92L4305.48,1187.66C4271.15,1140.41 4234.98,1094.52 4197.02,1050.07Z" style={{fill: "white", stroke: "#787878", strokeWidth: "11.67px"}}/>
                <path d="M4403.38,1334.09L4517.67,1264.06C4485.29,1211.27 4450.88,1159.72 4414.45,1109.59L4306.04,1188.42C4340.37,1235.67 4372.84,1284.26 4403.38,1334.09Z" style={{fill: "white", stroke: "#787878", strokeWidth: "11.67px"}}/>
                <path d="M4402.94,1333.39L4517.23,1263.36C4549.56,1316.17 4579.86,1370.23 4608,1425.45L4488.54,1486.25C4462.03,1434.22 4433.48,1383.23 4402.94,1333.39Z" style={{fill: "white", stroke: "#787878", strokeWidth: "11.67px"}}/>

                <path d="M4562.33,1646.2L4686.16,1594.91C4662.44,1537.7 4636.52,1481.41 4608.38,1426.19L4488.98,1487.1C4515.49,1539.13 4539.96,1592.2 4562.33,1646.2Z" style={{fill: `${outerRing[1].charAt(1) === "1" ? "#DF6A33" : "#FFFFFF"}`, stroke: "#787878", strokeWidth: "11.67px"}}/>
                <path d="M4562.02,1645.44L4685.86,1594.15C4709.53,1651.37 4731,1709.5 4750.15,1768.44L4622.65,1809.81C4604.61,1754.27 4584.38,1699.44 4562.02,1645.44Z" style={{fill: `${outerRing[1].charAt(2) === "1" ? "#DF6A33" : "#FFFFFF"}`, stroke: "#787878", strokeWidth: "11.67px"}}/>
                <path d="M4670.51,1979.33L4800.84,1948.04C4786.36,1887.83 4769.57,1828.18 4750.41,1769.24L4622.95,1810.71C4641,1866.25 4656.86,1922.5 4670.51,1979.33Z" style={{fill: `${outerRing[1].charAt(3) === "1" ? "#DF6A33" : "#FFFFFF"}`, stroke: "#787878", strokeWidth: "11.67px"}}/>
                <path d="M4670.31,1978.53L4800.65,1947.24C4815.08,2007.46 4827.19,2068.24 4836.89,2129.45L4704.49,2150.36C4695.35,2092.68 4683.95,2035.36 4670.31,1978.53Z" style={{fill: `${outerRing[1].charAt(4) === "1" ? "#DF6A33" : "#FFFFFF"}`, stroke: "#787878", strokeWidth: "11.67px"}}/>
                <path d="M4725.23,2325.28L4858.86,2314.76C4853.97,2253.03 4846.71,2191.48 4837.02,2130.27L4704.63,2151.3C4713.77,2208.98 4720.64,2267.01 4725.23,2325.28Z" style={{fill: `${outerRing[1].charAt(5) === "1" ? "#DF6A33" : "#FFFFFF"}`, stroke: "#787878", strokeWidth: "11.67px"}}/>
                <path d="M4725.17,2324.46L4858.8,2313.94C4863.63,2375.68 4866.09,2437.61 4866.09,2499.58L4732.05,2499.52C4732.05,2441.12 4729.75,2382.73 4725.17,2324.46Z" style={{fill: `${outerRing[1].charAt(6) === "1" ? "#DF6A33" : "#FFFFFF"}`, stroke: "#787878", strokeWidth: "11.67px"}}/>
                <path d="M4725.17,2675.54L4858.8,2686.06C4863.63,2624.32 4866.09,2562.39 4866.09,2500.42L4732.05,2500.48C4732.05,2558.88 4729.75,2617.27 4725.17,2675.54Z" style={{fill: `${outerRing[1].charAt(7) === "1" ? "#DF6A33" : "#FFFFFF"}`, stroke: "#787878", strokeWidth: "11.67px"}}/>

                <path d="M4725.23,2674.71L4858.86,2685.23C4853.97,2746.96 4846.71,2808.51 4837.02,2869.72L4704.64,2848.69C4713.77,2791.01 4720.64,2732.98 4725.23,2674.71Z" style={{fill: "white", stroke: "#787878", strokeWidth: "11.67px"}}/>
                <path d="M4670.31,3021.47L4800.65,3052.76C4815.08,2992.54 4827.19,2931.76 4836.89,2870.55L4704.49,2849.64C4695.35,2907.32 4683.95,2964.64 4670.31,3021.47Z" style={{fill: "white", stroke: "#787878", strokeWidth: "11.67px"}}/>
                <path d="M4670.51,3020.67L4800.85,3051.96C4786.36,3112.17 4769.57,3171.82 4750.41,3230.77L4622.95,3189.29C4641,3133.75 4656.86,3077.5 4670.51,3020.67Z" style={{fill: "white", stroke: "#787878", strokeWidth: "11.67px"}}/>

                <path d="M4562.02,3354.56L4685.86,3405.85C4709.53,3348.63 4731,3290.5 4750.15,3231.56L4622.65,3190.19C4604.61,3245.73 4584.38,3300.56 4562.02,3354.56Z" style={{fill: `${outerRing[2].charAt(1) === "1" ? "#DF6A33" : "#FFFFFF"}`, stroke: "#787878", strokeWidth: "11.67px"}}/>
                <path d="M4562.34,3353.8L4686.18,3405.09C4662.45,3462.3 4636.53,3518.59 4608.39,3573.81L4488.99,3512.9C4515.5,3460.87 4539.97,3407.8 4562.34,3353.8Z" style={{fill: `${outerRing[2].charAt(2) === "1" ? "#DF6A33" : "#FFFFFF"}`, stroke: "#787878", strokeWidth: "11.67px"}}/>
                <path d="M4402.95,3666.61L4517.24,3736.64C4549.57,3683.83 4579.88,3629.77 4608.01,3574.55L4488.55,3513.75C4462.04,3565.78 4433.49,3616.78 4402.95,3666.61Z" style={{fill: `${outerRing[2].charAt(3) === "1" ? "#DF6A33" : "#FFFFFF"}`, stroke: "#787878", strokeWidth: "11.67px"}}/>
                <path d="M4403.38,3665.91L4517.67,3735.95C4485.29,3788.73 4450.88,3840.28 4414.45,3890.41L4306.04,3811.58C4340.37,3764.33 4372.84,3715.74 4403.38,3665.91Z" style={{fill: `${outerRing[2].charAt(4) === "1" ? "#DF6A33" : "#FFFFFF"}`, stroke: "#787878", strokeWidth: "11.67px"}}/>
                <path d="M4197.02,3949.93L4298.94,4036.98C4339.14,3989.88 4377.53,3941.22 4413.96,3891.08L4305.48,3812.34C4271.15,3859.59 4234.98,3905.49 4197.02,3949.93Z" style={{fill: `${outerRing[2].charAt(5) === "1" ? "#DF6A33" : "#FFFFFF"}`, stroke: "#787878", strokeWidth: "11.67px"}}/>
                <path d="M4197.56,3949.31L4299.48,4036.36C4259.25,4083.43 4217.2,4128.96 4173.37,4172.78L4078.63,4077.96C4119.93,4036.66 4159.6,3993.75 4197.56,3949.31Z" style={{fill: `${outerRing[2].charAt(6) === "1" ? "#DF6A33" : "#FFFFFF"}`, stroke: "#787878", strokeWidth: "11.67px"}}/>
                <path d="M3949.31,4197.56L4036.36,4299.48C4083.43,4259.25 4128.96,4217.2 4172.78,4173.37L4077.96,4078.63C4036.66,4119.93 3993.75,4159.6 3949.31,4197.56Z" style={{fill: `${outerRing[2].charAt(7) === "1" ? "#DF6A33" : "#FFFFFF"}`, stroke: "#787878", strokeWidth: "11.67px"}}/>

                <path d="M3949.93,4197.02L4036.98,4298.94C3989.88,4339.14 3941.22,4377.53 3891.08,4413.96L3812.34,4305.48C3859.59,4271.15 3905.49,4234.98 3949.93,4197.02Z" style={{fill: "white", stroke: "#787878", strokeWidth: "11.67px"}}/>
                <path d="M3665.91,4403.38L3735.95,4517.67C3788.73,4485.29 3840.28,4450.88 3890.41,4414.45L3811.58,4306.04C3764.33,4340.37 3715.74,4372.84 3665.91,4403.38Z" style={{fill: "white", stroke: "#787878", strokeWidth: "11.67px"}}/>
                <path d="M3666.61,4402.94L3736.64,4517.23C3683.83,4549.56 3629.77,4579.86 3574.55,4608L3513.75,4488.54C3565.78,4462.03 3616.78,4433.48 3666.61,4402.94Z" style={{fill: "white", stroke: "#787878", strokeWidth: "11.67px"}}/>

                <path d="M3353.8,4562.33L3405.09,4686.16C3462.3,4662.44 3518.59,4636.52 3573.81,4608.38L3512.9,4488.98C3460.87,4515.49 3407.8,4539.96 3353.8,4562.33Z" style={{fill: `${outerRing[3].charAt(1) === "1" ? "#DF6A33" : "#FFFFFF"}`, stroke: "#787878", strokeWidth: "11.67px"}}/>
                <path d="M3354.56,4562.02L3405.85,4685.86C3348.63,4709.53 3290.5,4731 3231.56,4750.15L3190.19,4622.65C3245.73,4604.61 3300.56,4584.38 3354.56,4562.02Z" style={{fill: `${outerRing[3].charAt(2) === "1" ? "#DF6A33" : "#FFFFFF"}`, stroke: "#787878", strokeWidth: "11.67px"}}/>
                <path d="M3020.67,4670.51L3051.96,4800.84C3112.17,4786.36 3171.82,4769.57 3230.76,4750.41L3189.29,4622.95C3133.75,4641 3077.5,4656.86 3020.67,4670.51Z" style={{fill: `${outerRing[3].charAt(3) === "1" ? "#DF6A33" : "#FFFFFF"}`, stroke: "#787878", strokeWidth: "11.67px"}}/>
                <path d="M3021.46,4670.31L3052.75,4800.65C2992.53,4815.08 2931.75,4827.19 2870.54,4836.89L2849.63,4704.49C2907.31,4695.35 2964.63,4683.95 3021.46,4670.31Z" style={{fill: `${outerRing[3].charAt(4) === "1" ? "#DF6A33" : "#FFFFFF"}`, stroke: "#787878", strokeWidth: "11.67px"}}/>
                <path d="M2674.71,4725.23L2685.23,4858.86C2746.96,4853.97 2808.51,4846.71 2869.72,4837.02L2848.69,4704.63C2791.01,4713.77 2732.98,4720.64 2674.71,4725.23Z" style={{fill: `${outerRing[3].charAt(5) === "1" ? "#DF6A33" : "#FFFFFF"}`, stroke: "#787878", strokeWidth: "11.67px"}}/>
                <path d="M2675.54,4725.17L2686.06,4858.8C2624.32,4863.63 2562.39,4866.09 2500.42,4866.09L2500.48,4732.05C2558.88,4732.05 2617.27,4729.75 2675.54,4725.17Z" style={{fill: `${outerRing[3].charAt(6) === "1" ? "#DF6A33" : "#FFFFFF"}`, stroke: "#787878", strokeWidth: "11.67px"}}/>
                <path d="M2324.46,4725.17L2313.94,4858.8C2375.68,4863.63 2437.61,4866.09 2499.58,4866.09L2499.52,4732.05C2441.12,4732.05 2382.73,4729.75 2324.46,4725.17Z" style={{fill: `${outerRing[3].charAt(7) === "1" ? "#DF6A33" : "#FFFFFF"}`, stroke: "#787878", strokeWidth: "11.67px"}}/>

                <path d="M2325.28,4725.23L2314.76,4858.86C2253.03,4853.97 2191.48,4846.71 2130.27,4837.02L2151.3,4704.64C2208.98,4713.77 2267.01,4720.64 2325.28,4725.23Z" style={{fill: "white", stroke: "#787878", strokeWidth: "11.67px"}}/>
                <path d="M1978.52,4670.31L1947.23,4800.65C2007.45,4815.08 2068.23,4827.19 2129.44,4836.89L2150.35,4704.49C2092.67,4695.35 2035.35,4683.95 1978.52,4670.31Z" style={{fill: "white", stroke: "#787878", strokeWidth: "11.67px"}}/>
                <path d="M1979.33,4670.51L1948.04,4800.85C1887.83,4786.36 1828.18,4769.57 1769.23,4750.41L1810.71,4622.95C1866.25,4641 1922.5,4656.86 1979.33,4670.51Z" style={{fill: "white", stroke: "#787878", strokeWidth: "11.67px"}}/>

                <path d="M1645.44,4562.02L1594.14,4685.86C1651.37,4709.53 1709.5,4731 1768.44,4750.15L1809.81,4622.65C1754.27,4604.61 1699.44,4584.38 1645.44,4562.02Z" style={{fill: `${outerRing[4].charAt(1) === "1" ? "#DF6A33" : "#FFFFFF"}`, stroke: "#787878", strokeWidth: "11.67px"}}/>
                <path d="M1646.21,4562.34L1594.92,4686.18C1537.71,4662.45 1481.42,4636.53 1426.2,4608.39L1487.11,4488.99C1539.14,4515.5 1592.21,4539.97 1646.21,4562.34Z" style={{fill: `${outerRing[4].charAt(2) === "1" ? "#DF6A33" : "#FFFFFF"}`, stroke: "#787878", strokeWidth: "11.67px"}}/>
                <path d="M1333.4,4402.95L1263.37,4517.24C1316.18,4549.57 1370.24,4579.88 1425.46,4608.01L1486.26,4488.55C1434.23,4462.04 1383.24,4433.49 1333.4,4402.95Z" style={{fill: `${outerRing[4].charAt(3) === "1" ? "#DF6A33" : "#FFFFFF"}`, stroke: "#787878", strokeWidth: "11.67px"}}/>
                <path d="M1334.08,4403.38L1264.05,4517.67C1211.26,4485.29 1159.71,4450.88 1109.58,4414.45L1188.41,4306.04C1235.66,4340.37 1284.25,4372.84 1334.08,4403.38Z" style={{fill: `${outerRing[4].charAt(4) === "1" ? "#DF6A33" : "#FFFFFF"}`, stroke: "#787878", strokeWidth: "11.67px"}}/>
                <path d="M1050.06,4197.02L963.009,4298.94C1010.12,4339.14 1058.77,4377.53 1108.91,4413.96L1187.65,4305.48C1140.4,4271.15 1094.51,4234.98 1050.06,4197.02Z" style={{fill: `${outerRing[4].charAt(5) === "1" ? "#DF6A33" : "#FFFFFF"}`, stroke: "#787878", strokeWidth: "11.67px"}}/>
                <path d="M1050.7,4197.56L963.649,4299.48C916.576,4259.25 871.05,4217.2 827.227,4173.37L922.05,4078.63C963.345,4119.93 1006.26,4159.6 1050.7,4197.56Z" style={{fill: `${outerRing[4].charAt(6) === "1" ? "#DF6A33" : "#FFFFFF"}`, stroke: "#787878", strokeWidth: "11.67px"}}/>
                <path d="M802.449,3949.31L700.525,4036.36C740.763,4083.43 782.812,4128.96 826.635,4172.78L921.376,4077.96C880.08,4036.66 840.409,3993.75 802.449,3949.31Z" style={{fill: `${outerRing[4].charAt(7) === "1" ? "#DF6A33" : "#FFFFFF"}`, stroke: "#787878", strokeWidth: "11.67px"}}/>

                <path d="M802.981,3949.93L701.057,4036.98C660.859,3989.88 622.471,3941.22 586.043,3891.08L694.52,3812.34C728.846,3859.59 765.024,3905.49 802.981,3949.93Z" style={{fill: "white", stroke: "#787878", strokeWidth: "11.67px"}}/>
                <path d="M596.624,3665.91L482.338,3735.95C514.716,3788.73 549.126,3840.28 585.554,3890.41L693.961,3811.58C659.635,3764.33 627.164,3715.74 596.624,3665.91Z" style={{fill: "white", stroke: "#787878", strokeWidth: "11.67px"}}/>
                <path d="M597.038,3666.61L482.752,3736.64C450.417,3683.83 420.113,3629.77 391.977,3574.55L511.436,3513.75C537.949,3565.78 566.502,3616.78 597.038,3666.61Z" style={{fill: "white", stroke: "#787878", strokeWidth: "11.67px"}}/>

                <path d="M437.654,3353.8L313.818,3405.09C337.54,3462.3 363.463,3518.59 391.599,3573.81L511.005,3512.9C484.492,3460.87 460.022,3407.8 437.654,3353.8Z" style={{fill: `${outerRing[5].charAt(1) === "1" ? "#DF6A33" : "#FFFFFF"}`, stroke: "#787878", strokeWidth: "11.67px"}}/>
                <path d="M438.003,3354.56L314.168,3405.85C290.493,3348.63 269.02,3290.5 249.868,3231.56L377.368,3190.19C395.414,3245.73 415.639,3300.56 438.003,3354.56Z" style={{fill: `${outerRing[5].charAt(2) === "1" ? "#DF6A33" : "#FFFFFF"}`, stroke: "#787878", strokeWidth: "11.67px"}}/>
                <path d="M329.515,3020.67L199.18,3051.96C213.662,3112.17 230.459,3171.82 249.611,3230.76L377.074,3189.29C359.027,3133.75 343.162,3077.5 329.515,3020.67Z" style={{fill: `${outerRing[5].charAt(3) === "1" ? "#DF6A33" : "#FFFFFF"}`, stroke: "#787878", strokeWidth: "11.67px"}}/>
                <path d="M329.694,3021.46L199.36,3052.75C184.928,2992.53 172.813,2931.75 163.118,2870.54L295.518,2849.63C304.654,2907.31 316.053,2964.63 329.694,3021.46Z" style={{fill: `${outerRing[5].charAt(4) === "1" ? "#DF6A33" : "#FFFFFF"}`, stroke: "#787878", strokeWidth: "11.67px"}}/>
                <path d="M274.774,2674.71L141.149,2685.23C146.033,2746.96 153.293,2808.51 162.988,2869.72L295.37,2848.69C286.234,2791.01 279.362,2732.98 274.774,2674.71Z" style={{fill: `${outerRing[5].charAt(5) === "1" ? "#DF6A33" : "#FFFFFF"}`, stroke: "#787878", strokeWidth: "11.67px"}}/>
                <path d="M274.833,2675.54L141.208,2686.06C136.375,2624.32 133.917,2562.39 133.917,2500.42L267.958,2500.48C267.958,2558.88 270.25,2617.27 274.833,2675.54Z" style={{fill: `${outerRing[5].charAt(6) === "1" ? "#DF6A33" : "#FFFFFF"}`, stroke: "#787878", strokeWidth: "11.67px"}}/>
                <path d="M274.833,2324.46L141.208,2313.94C136.375,2375.68 133.917,2437.61 133.917,2499.58L267.958,2499.52C267.958,2441.12 270.25,2382.73 274.833,2324.46Z" style={{fill: `${outerRing[5].charAt(7) === "1" ? "#DF6A33" : "#FFFFFF"}`, stroke: "#787878", strokeWidth: "11.67px"}}/>

                <path d="M274.783,2325.28L141.158,2314.76C146.042,2253.03 153.301,2191.48 162.996,2130.27L295.378,2151.3C286.243,2208.98 279.371,2267.01 274.783,2325.28Z" style={{fill: "white", stroke: "#787878", strokeWidth: "11.67px"}}/>
                <path d="M329.703,1978.52L199.368,1947.23C184.937,2007.45 172.822,2068.23 163.127,2129.44L295.527,2150.35C304.663,2092.67 316.061,2035.35 329.703,1978.52Z" style={{fill: "white", stroke: "#787878", strokeWidth: "11.67px"}}/>
                <path d="M329.495,1979.33L199.16,1948.04C213.642,1887.83 230.439,1828.18 249.591,1769.23L377.054,1810.71C359.007,1866.25 343.142,1922.5 329.495,1979.33Z" style={{fill: "white", stroke: "#787878", strokeWidth: "11.67px"}}/>

                <path d="M437.983,1645.44L314.148,1594.14C290.473,1651.37 269,1709.5 249.848,1768.44L377.348,1809.81C395.394,1754.27 415.619,1699.44 437.983,1645.44Z" style={{fill: `${outerRing[6].charAt(1) === "1" ? "#DF6A33" : "#FFFFFF"}`, stroke: "#787878", strokeWidth: "11.67px"}}/>
                <path d="M437.665,1646.21L313.83,1594.92C337.552,1537.71 363.475,1481.42 391.611,1426.2L511.016,1487.11C484.503,1539.14 460.034,1592.21 437.665,1646.21Z" style={{fill: `${outerRing[6].charAt(2) === "1" ? "#DF6A33" : "#FFFFFF"}`, stroke: "#787878", strokeWidth: "11.67px"}}/>
                <path d="M597.05,1333.4L482.764,1263.37C450.429,1316.18 420.125,1370.24 391.989,1425.46L511.448,1486.26C537.961,1434.23 566.514,1383.24 597.05,1333.4Z" style={{fill: `${outerRing[6].charAt(3) === "1" ? "#DF6A33" : "#FFFFFF"}`, stroke: "#787878", strokeWidth: "11.67px"}}/>
                <path d="M596.622,1334.08L482.336,1264.05C514.714,1211.26 549.124,1159.71 585.552,1109.58L693.959,1188.41C659.633,1235.66 627.163,1284.25 596.622,1334.08Z" style={{fill: `${outerRing[6].charAt(4) === "1" ? "#DF6A33" : "#FFFFFF"}`, stroke: "#787878", strokeWidth: "11.67px"}}/>
                <path d="M802.979,1050.06L701.056,963.009C660.857,1010.12 622.469,1058.77 586.042,1108.91L694.518,1187.65C728.844,1140.4 765.023,1094.51 802.979,1050.06Z" style={{fill: `${outerRing[6].charAt(5) === "1" ? "#DF6A33" : "#FFFFFF"}`, stroke: "#787878", strokeWidth: "11.67px"}}/>
                <path d="M802.444,1050.7L700.52,963.649C740.758,916.576 782.807,871.05 826.63,827.227L921.371,922.05C880.076,963.345 840.404,1006.26 802.444,1050.7Z" style={{fill: `${outerRing[6].charAt(6) === "1" ? "#DF6A33" : "#FFFFFF"}`, stroke: "#787878", strokeWidth: "11.67px"}}/>
                <path d="M1050.69,802.449L963.639,700.525C916.566,740.763 871.04,782.812 827.217,826.635L922.04,921.376C963.335,880.08 1006.25,840.409 1050.69,802.449Z" style={{fill: `${outerRing[6].charAt(7) === "1" ? "#DF6A33" : "#FFFFFF"}`, stroke: "#787878", strokeWidth: "11.67px"}}/>

                <path d="M1050.07,802.981L963.019,701.057C1010.12,660.859 1058.78,622.471 1108.92,586.043L1187.66,694.52C1140.41,728.846 1094.52,765.024 1050.07,802.981Z" style={{fill: "white", stroke: "#787878", strokeWidth: "11.67px"}}/>
                <path d="M1334.09,596.624L1264.06,482.338C1211.27,514.716 1159.72,549.126 1109.59,585.554L1188.42,693.961C1235.67,659.635 1284.26,627.164 1334.09,596.624Z" style={{fill: "white", stroke: "#787878", strokeWidth: "11.67px"}}/>
                <path d="M1333.39,597.038L1263.36,482.752C1316.17,450.417 1370.23,420.113 1425.45,391.977L1486.25,511.436C1434.22,537.949 1383.23,566.502 1333.39,597.038Z" style={{fill: "white", stroke: "#787878", strokeWidth: "11.67px"}}/>

                <path d="M1646.2,437.654L1594.91,313.818C1537.7,337.54 1481.41,363.463 1426.19,391.599L1487.1,511.005C1539.13,484.492 1592.2,460.022 1646.2,437.654Z" style={{fill: `${outerRing[7].charAt(1) === "1" ? "#DF6A33" : "#FFFFFF"}`, stroke: "#787878", strokeWidth: "11.67px"}}/>
                <path d="M1645.44,438.003L1594.15,314.168C1651.37,290.493 1709.5,269.02 1768.44,249.868L1809.81,377.368C1754.27,395.414 1699.44,415.639 1645.44,438.003Z" style={{fill: `${outerRing[7].charAt(2) === "1" ? "#DF6A33" : "#FFFFFF"}`, stroke: "#787878", strokeWidth: "11.67px"}}/>
                <path d="M1979.33,329.515L1948.04,199.18C1887.83,213.662 1828.18,230.459 1769.24,249.611L1810.71,377.074C1866.25,359.027 1922.5,343.162 1979.33,329.515Z" style={{fill: `${outerRing[7].charAt(3) === "1" ? "#DF6A33" : "#FFFFFF"}`, stroke: "#787878", strokeWidth: "11.67px"}}/>
                <path d="M1978.53,329.694L1947.24,199.36C2007.46,184.928 2068.24,172.813 2129.45,163.118L2150.36,295.518C2092.68,304.654 2035.36,316.053 1978.53,329.694Z" style={{fill: `${outerRing[7].charAt(4) === "1" ? "#DF6A33" : "#FFFFFF"}`, stroke: "#787878", strokeWidth: "11.67px"}}/>
                <path d="M2325.28,274.774L2314.76,141.149C2253.03,146.033 2191.48,153.293 2130.27,162.988L2151.3,295.37C2208.98,286.234 2267.01,279.362 2325.28,274.774Z" style={{fill: `${outerRing[7].charAt(5) === "1" ? "#DF6A33" : "#FFFFFF"}`, stroke: "#787878", strokeWidth: "11.67px"}}/>
                <path d="M2324.46,274.833L2313.94,141.208C2375.68,136.375 2437.61,133.917 2499.58,133.917L2499.52,267.958C2441.12,267.958 2382.73,270.25 2324.46,274.833Z" style={{fill: `${outerRing[7].charAt(6) === "1" ? "#DF6A33" : "#FFFFFF"}`, stroke: "#787878", strokeWidth: "11.67px"}}/>
            </g>

            <g id="second-ring">
                <path d="M2618.75,982.083L2665.12,408.333C2610.33,404.042 2555.42,401.875 2500.5,401.875L2500.5,839.775L2618.75,982.083Z" style={{fill: `${secondRing[5].charAt(7) === "1" || secondRing[5] === "00000000" ? "#DF6A33" : "#FFFFFF"}`, stroke: "#787878", strokeWidth: "11.67px"}}/>

                <path d="M2620.16,982.209L2664.11,408.268C2718.9,412.601 2773.47,419.051 2827.71,427.642L2759.21,860.15L2620.16,982.209Z" style={{fill: `${secondRing[5] === "00000000" && secondRing[6] === "00000000" ? "#DF6A33" : "#FFFFFF"}`, stroke: "#787878", strokeWidth: "11.67px"}}/>
                <path d="M2854.74,1019.36L2990.3,459.929C2936.85,447.119 2882.95,436.388 2828.71,427.797L2760.21,860.306L2854.74,1019.36Z" style={{fill: `${secondRing[5] === "00000000" && secondRing[6] === "00000000" ? "#DF6A33" : "#FFFFFF"}`, stroke: "#787878", strokeWidth: "11.67px"}}/>
                <path d="M2856.13,1019.68L2989.32,459.68C3042.76,472.53 3095.65,487.438 3147.88,504.408L3012.56,920.876L2856.13,1019.68Z" style={{fill: `${secondRing[5] === "00000000" && secondRing[6] === "00000000" ? "#DF6A33" : "#FFFFFF"}`, stroke: "#787878", strokeWidth: "11.67px"}}/>

                <path d="M3082,1093.07L3303.4,561.732C3252.62,540.719 3201.07,521.689 3148.84,504.719L3013.52,921.187L3082,1093.07Z" style={{fill: `${secondRing[6].charAt(1) === "1" || secondRing[6] === "00000000" ? "#DF6A33" : "#FFFFFF"}`, stroke: "#787878", strokeWidth: "11.67px"}}/>
                <path d="M3083.31,1093.61L3302.47,561.341C3353.24,582.392 3403.15,605.391 3452.08,630.323L3253.28,1020.5L3083.31,1093.61Z" style={{fill: `${secondRing[6].charAt(2) === "1" || secondRing[6] === "00000000" ? "#DF6A33" : "#FFFFFF"}`, stroke: "#787878", strokeWidth: "11.67px"}}/>
                <path d="M3294.93,1201.43L3596.73,711.268C3549.86,682.569 3501.91,655.709 3452.98,630.777L3254.18,1020.95L3294.93,1201.43Z" style={{fill: `${secondRing[6].charAt(3) === "1" || secondRing[6] === "00000000" ? "#DF6A33" : "#FFFFFF"}`, stroke: "#787878", strokeWidth: "11.67px"}}/>
                <path d="M3296.14,1202.18L3595.86,710.748C3642.71,739.482 3688.41,770.006 3732.84,802.285L3475.45,1156.55L3296.14,1202.18Z" style={{fill: `${secondRing[6].charAt(4) === "1" || secondRing[6] === "00000000" ? "#DF6A33" : "#FFFFFF"}`, stroke: "#787878", strokeWidth: "11.67px"}}/>
                <path d="M3488.28,1341.78L3863.04,904.865C3821.24,869.188 3778.08,835.158 3733.66,802.879L3476.26,1157.15L3488.28,1341.78Z" style={{fill: `${secondRing[6].charAt(5) === "1" || secondRing[6] === "00000000" ? "#DF6A33" : "#FFFFFF"}`, stroke: "#787878", strokeWidth: "11.67px"}}/>
                <path d="M3489.36,1342.7L3862.27,904.206C3904.05,939.915 3944.41,977.211 3983.24,1016.04L3673.6,1325.69L3489.36,1342.7Z" style={{fill: `${secondRing[6].charAt(6) === "1" || secondRing[6] === "00000000" ? "#DF6A33" : "#FFFFFF"}`, stroke: "#787878", strokeWidth: "11.67px"}}/>
                <path d="M3657.3,1510.64L4095.8,1137.73C4060.09,1095.95 4022.79,1055.59 3983.96,1016.76L3674.32,1326.4L3657.3,1510.64Z" style={{fill: `${secondRing[6].charAt(7) === "1" || secondRing[6] === "00000000" ? "#DF6A33" : "#FFFFFF"}`, stroke: "#787878", strokeWidth: "11.67px"}}/>

                <path d="M3658.22,1511.72L4095.14,1136.96C4130.81,1178.77 4164.84,1221.92 4197.12,1266.35L3842.85,1523.74L3658.22,1511.72Z" style={{fill: `${secondRing[6] === "00000000" && secondRing[7] === "00000000" ? "#DF6A33" : "#FFFFFF"}`, stroke: "#787878", strokeWidth: "11.67px"}}/>
                <path d="M3797.82,1703.86L4289.25,1404.14C4260.52,1357.29 4229.99,1311.59 4197.72,1267.16L3843.45,1524.55L3797.82,1703.86Z" style={{fill: `${secondRing[6] === "00000000" && secondRing[7] === "00000000" ? "#DF6A33" : "#FFFFFF"}`, stroke: "#787878", strokeWidth: "11.67px"}}/>
                <path d="M3798.56,1705.08L4288.72,1403.28C4317.42,1450.15 4344.28,1498.1 4369.21,1547.03L3979.04,1745.83L3798.56,1705.08Z" style={{fill: `${secondRing[6] === "00000000" && secondRing[7] === "00000000" ? "#DF6A33" : "#FFFFFF"}`, stroke: "#787878", strokeWidth: "11.67px"}}/>

                <path d="M3906.38,1916.69L4438.65,1697.53C4417.6,1646.77 4394.6,1596.86 4369.67,1547.92L3979.49,1746.73L3906.38,1916.69Z" style={{fill: `${secondRing[7].charAt(1) === "1" || secondRing[7] === "00000000" ? "#DF6A33" : "#FFFFFF"}`, stroke: "#787878", strokeWidth: "11.67px"}}/>
                <path d="M3906.93,1918L4438.27,1696.6C4459.28,1747.38 4478.31,1798.94 4495.28,1851.16L4078.81,1986.48L3906.93,1918Z" style={{fill: `${secondRing[7].charAt(2) === "1" || secondRing[7] === "00000000" ? "#DF6A33" : "#FFFFFF"}`, stroke: "#787878", strokeWidth: "11.67px"}}/>
                <path d="M3980.32,2143.88L4540.32,2010.69C4527.47,1957.25 4512.56,1904.36 4495.59,1852.13L4079.12,1987.45L3980.32,2143.88Z" style={{fill: `${secondRing[7].charAt(3) === "1" || secondRing[7] === "00000000" ? "#DF6A33" : "#FFFFFF"}`, stroke: "#787878", strokeWidth: "11.67px"}}/>
                <path d="M3980.65,2145.26L4540.08,2009.7C4552.89,2063.15 4563.62,2117.05 4572.21,2171.29L4139.7,2239.79L3980.65,2145.26Z" style={{fill: `${secondRing[7].charAt(4) === "1" || secondRing[7] === "00000000" ? "#DF6A33" : "#FFFFFF"}`, stroke: "#787878", strokeWidth: "11.67px"}}/>
                <path d="M4017.8,2379.84L4591.74,2335.89C4587.41,2281.1 4580.96,2226.53 4572.37,2172.29L4139.86,2240.79L4017.8,2379.84Z" style={{fill: `${secondRing[7].charAt(5) === "1" || secondRing[7] === "00000000" ? "#DF6A33" : "#FFFFFF"}`, stroke: "#787878", strokeWidth: "11.67px"}}/>
                <path d="M4017.92,2381.25L4591.67,2334.88C4595.96,2389.67 4598.13,2444.58 4598.13,2499.5L4160.23,2499.5L4017.92,2381.25Z" style={{fill: `${secondRing[7].charAt(6) === "1" || secondRing[7] === "00000000" ? "#DF6A33" : "#FFFFFF"}`, stroke: "#787878", strokeWidth: "11.67px"}}/>
                <path d="M4017.92,2618.75L4591.67,2665.12C4595.96,2610.33 4598.13,2555.42 4598.13,2500.5L4160.23,2500.5L4017.92,2618.75Z" style={{fill: `${secondRing[7].charAt(7) === "1" || secondRing[7] === "00000000" ? "#DF6A33" : "#FFFFFF"}`, stroke: "#787878", strokeWidth: "11.67px"}}/>

                <path d="M4017.8,2620.16L4591.74,2664.11C4587.41,2718.9 4580.96,2773.47 4572.37,2827.71L4139.86,2759.21L4017.8,2620.16Z" style={{fill: `${secondRing[7] === "00000000" && secondRing[0] === "00000000" ? "#DF6A33" : "#FFFFFF"}`, stroke: "#787878", strokeWidth: "11.67px"}}/>
                <path d="M3980.65,2854.74L4540.08,2990.3C4552.89,2936.85 4563.62,2882.95 4572.21,2828.71L4139.7,2760.21L3980.65,2854.74Z" style={{fill: `${secondRing[7] === "00000000" && secondRing[0] === "00000000" ? "#DF6A33" : "#FFFFFF"}`, stroke: "#787878", strokeWidth: "11.67px"}}/>
                <path d="M3980.32,2856.13L4540.32,2989.32C4527.47,3042.76 4512.56,3095.65 4495.59,3147.88L4079.12,3012.56L3980.32,2856.13Z" style={{fill: `${secondRing[7] === "00000000" && secondRing[0] === "00000000" ? "#DF6A33" : "#FFFFFF"}`, stroke: "#787878", strokeWidth: "11.67px"}}/>

                <path d="M3906.93,3082L4438.27,3303.4C4459.28,3252.62 4478.31,3201.07 4495.28,3148.84L4078.81,3013.52L3906.93,3082Z" style={{fill: `${secondRing[0].charAt(1) === "1" || secondRing[0] === "00000000" ? "#DF6A33" : "#FFFFFF"}`, stroke: "#787878", strokeWidth: "11.67px"}}/>
                <path d="M3906.39,3083.31L4438.66,3302.47C4417.61,3353.24 4394.61,3403.15 4369.68,3452.08L3979.51,3253.28L3906.39,3083.31Z" style={{fill: `${secondRing[0].charAt(2) === "1" || secondRing[0] === "00000000" ? "#DF6A33" : "#FFFFFF"}`, stroke: "#787878", strokeWidth: "11.67px"}}/>
                <path d="M3798.57,3294.93L4288.73,3596.73C4317.43,3549.86 4344.29,3501.91 4369.22,3452.98L3979.05,3254.18L3798.57,3294.93Z" style={{fill: `${secondRing[0].charAt(3) === "1" || secondRing[0] === "00000000" ? "#DF6A33" : "#FFFFFF"}`, stroke: "#787878", strokeWidth: "11.67px"}}/>
                <path d="M3797.82,3296.14L4289.25,3595.86C4260.52,3642.71 4229.99,3688.41 4197.72,3732.84L3843.45,3475.45L3797.82,3296.14Z" style={{fill: `${secondRing[0].charAt(4) === "1" || secondRing[0] === "00000000" ? "#DF6A33" : "#FFFFFF"}`, stroke: "#787878", strokeWidth: "11.67px"}}/>
                <path d="M3658.22,3488.28L4095.14,3863.04C4130.81,3821.24 4164.84,3778.08 4197.12,3733.66L3842.85,3476.26L3658.22,3488.28Z" style={{fill: `${secondRing[0].charAt(5) === "1" || secondRing[0] === "00000000" ? "#DF6A33" : "#FFFFFF"}`, stroke: "#787878", strokeWidth: "11.67px"}}/>
                <path d="M3657.3,3489.36L4095.79,3862.27C4060.09,3904.05 4022.79,3944.41 3983.96,3983.24L3674.31,3673.6L3657.3,3489.36Z" style={{fill: `${secondRing[0].charAt(6) === "1" || secondRing[0] === "00000000" ? "#DF6A33" : "#FFFFFF"}`, stroke: "#787878", strokeWidth: "11.67px"}}/>
                <path d="M3489.36,3657.3L3862.27,4095.8C3904.05,4060.09 3944.41,4022.79 3983.24,3983.96L3673.6,3674.32L3489.36,3657.3Z" style={{fill: `${secondRing[0].charAt(7) === "1" || secondRing[0] === "00000000" ? "#DF6A33" : "#FFFFFF"}`, stroke: "#787878", strokeWidth: "11.67px"}}/>

                <path d="M3488.28,3658.22L3863.04,4095.14C3821.24,4130.81 3778.08,4164.84 3733.66,4197.12L3476.26,3842.85L3488.28,3658.22Z" style={{fill: `${secondRing[0] === "00000000" && secondRing[1] === "00000000" ? "#DF6A33" : "#FFFFFF"}`, stroke: "#787878", strokeWidth: "11.67px"}}/>
                <path d="M3296.14,3797.82L3595.86,4289.25C3642.71,4260.52 3688.41,4229.99 3732.84,4197.72L3475.45,3843.45L3296.14,3797.82Z" style={{fill: `${secondRing[0] === "00000000" && secondRing[1] === "00000000" ? "#DF6A33" : "#FFFFFF"}`, stroke: "#787878", strokeWidth: "11.67px"}}/>
                <path d="M3294.93,3798.56L3596.73,4288.72C3549.86,4317.42 3501.91,4344.28 3452.98,4369.21L3254.18,3979.04L3294.93,3798.56Z" style={{fill: `${secondRing[0] === "00000000" && secondRing[1] === "00000000" ? "#DF6A33" : "#FFFFFF"}`, stroke: "#787878", strokeWidth: "11.67px"}}/>

                <path d="M3083.31,3906.38L3302.47,4438.65C3353.23,4417.6 3403.14,4394.6 3452.08,4369.67L3253.27,3979.49L3083.31,3906.38Z" style={{fill: `${secondRing[1].charAt(1) === "1" || secondRing[1] === "00000000" ? "#DF6A33" : "#FFFFFF"}`, stroke: "#787878", strokeWidth: "11.67px"}}/>
                <path d="M3082,3906.93L3303.4,4438.27C3252.62,4459.28 3201.06,4478.31 3148.84,4495.28L3013.52,4078.81L3082,3906.93Z" style={{fill: `${secondRing[1].charAt(2) === "1" || secondRing[1] === "00000000" ? "#DF6A33" : "#FFFFFF"}`, stroke: "#787878", strokeWidth: "11.67px"}}/>
                <path d="M2856.12,3980.32L2989.31,4540.32C3042.75,4527.47 3095.64,4512.56 3147.87,4495.59L3012.55,4079.12L2856.12,3980.32Z" style={{fill: `${secondRing[1].charAt(3) === "1" || secondRing[1] === "00000000" ? "#DF6A33" : "#FFFFFF"}`, stroke: "#787878", strokeWidth: "11.67px"}}/>
                <path d="M2854.74,3980.65L2990.3,4540.08C2936.85,4552.89 2882.95,4563.62 2828.71,4572.21L2760.21,4139.7L2854.74,3980.65Z" style={{fill: `${secondRing[1].charAt(4) === "1" || secondRing[1] === "00000000" ? "#DF6A33" : "#FFFFFF"}`, stroke: "#787878", strokeWidth: "11.67px"}}/>
                <path d="M2620.16,4017.8L2664.11,4591.74C2718.9,4587.41 2773.47,4580.96 2827.71,4572.37L2759.21,4139.86L2620.16,4017.8Z" style={{fill: `${secondRing[1].charAt(5) === "1" || secondRing[1] === "00000000" ? "#DF6A33" : "#FFFFFF"}`, stroke: "#787878", strokeWidth: "11.67px"}}/>
                <path d="M2618.75,4017.92L2665.12,4591.67C2610.33,4595.96 2555.42,4598.13 2500.5,4598.13L2500.5,4160.23L2618.75,4017.92Z" style={{fill: `${secondRing[1].charAt(6) === "1" || secondRing[1] === "00000000" ? "#DF6A33" : "#FFFFFF"}`, stroke: "#787878", strokeWidth: "11.67px"}}/>
                <path d="M2381.25,4017.92L2334.88,4591.67C2389.67,4595.96 2444.58,4598.13 2499.5,4598.13L2499.5,4160.23L2381.25,4017.92Z" style={{fill: `${secondRing[1].charAt(7) === "1" || secondRing[1] === "00000000" ? "#DF6A33" : "#FFFFFF"}`, stroke: "#787878", strokeWidth: "11.67px"}}/>

                <path d="M2379.83,4017.8L2335.88,4591.74C2281.09,4587.41 2226.52,4580.96 2172.28,4572.37L2240.78,4139.86L2379.83,4017.8Z" style={{fill: `${secondRing[1] === "00000000" && secondRing[2] === "00000000" ? "#DF6A33" : "#FFFFFF"}`, stroke: "#787878", strokeWidth: "11.67px"}}/>
                <path d="M2145.25,3980.65L2009.69,4540.08C2063.14,4552.89 2117.03,4563.62 2171.28,4572.21L2239.78,4139.7L2145.25,3980.65Z" style={{fill: `${secondRing[1] === "00000000" && secondRing[2] === "00000000" ? "#DF6A33" : "#FFFFFF"}`, stroke: "#787878", strokeWidth: "11.67px"}}/>
                <path d="M2143.88,3980.32L2010.69,4540.32C1957.25,4527.47 1904.36,4512.56 1852.13,4495.59L1987.45,4079.12L2143.88,3980.32Z" style={{fill: `${secondRing[1] === "00000000" && secondRing[2] === "00000000" ? "#DF6A33" : "#FFFFFF"}`, stroke: "#787878", strokeWidth: "11.67px"}}/>

                <path d="M1918,3906.93L1696.6,4438.27C1747.38,4459.28 1798.93,4478.31 1851.16,4495.28L1986.48,4078.81L1918,3906.93Z" style={{fill: `${secondRing[2].charAt(1) === "1" || secondRing[2] === "00000000" ? "#DF6A33" : "#FFFFFF"}`, stroke: "#787878", strokeWidth: "11.67px"}}/>
                <path d="M1916.7,3906.39L1697.54,4438.66C1646.78,4417.61 1596.87,4394.61 1547.93,4369.68L1746.74,3979.51L1916.7,3906.39Z" style={{fill: `${secondRing[2].charAt(2) === "1" || secondRing[2] === "00000000" ? "#DF6A33" : "#FFFFFF"}`, stroke: "#787878", strokeWidth: "11.67px"}}/>
                <path d="M1705.09,3798.57L1403.29,4288.73C1450.16,4317.43 1498.11,4344.29 1547.04,4369.22L1745.84,3979.05L1705.09,3798.57Z" style={{fill: `${secondRing[2].charAt(3) === "1" || secondRing[2] === "00000000" ? "#DF6A33" : "#FFFFFF"}`, stroke: "#787878", strokeWidth: "11.67px"}}/>
                <path d="M1703.85,3797.82L1404.13,4289.25C1357.28,4260.52 1311.58,4229.99 1267.15,4197.72L1524.54,3843.45L1703.85,3797.82Z" style={{fill: `${secondRing[2].charAt(4) === "1" || secondRing[2] === "00000000" ? "#DF6A33" : "#FFFFFF"}`, stroke: "#787878", strokeWidth: "11.67px"}}/>
                <path d="M1511.71,3658.22L1136.95,4095.14C1178.76,4130.81 1221.91,4164.84 1266.34,4197.12L1523.73,3842.85L1511.71,3658.22Z" style={{fill: `${secondRing[2].charAt(5) === "1" || secondRing[2] === "00000000" ? "#DF6A33" : "#FFFFFF"}`, stroke: "#787878", strokeWidth: "11.67px"}}/>
                <path d="M1510.64,3657.3L1137.73,4095.79C1095.95,4060.09 1055.59,4022.79 1016.76,3983.96L1326.4,3674.31L1510.64,3657.3Z" style={{fill: `${secondRing[2].charAt(6) === "1" || secondRing[2] === "00000000" ? "#DF6A33" : "#FFFFFF"}`, stroke: "#787878", strokeWidth: "11.67px"}}/>
                <path d="M1342.7,3489.36L904.206,3862.27C939.914,3904.05 977.211,3944.41 1016.04,3983.24L1325.68,3673.6L1342.7,3489.36Z" style={{fill: `${secondRing[2].charAt(7) === "1" || secondRing[2] === "00000000" ? "#DF6A33" : "#FFFFFF"}`, stroke: "#787878", strokeWidth: "11.67px"}}/>

                <path d="M1341.78,3488.28L904.865,3863.04C869.187,3821.24 835.158,3778.08 802.879,3733.66L1157.15,3476.26L1341.78,3488.28Z" style={{fill: `${secondRing[2] === "00000000" && secondRing[3] === "00000000" ? "#DF6A33" : "#FFFFFF"}`, stroke: "#787878", strokeWidth: "11.67px"}}/>
                <path d="M1202.18,3296.14L710.748,3595.86C739.482,3642.71 770.006,3688.41 802.285,3732.84L1156.55,3475.45L1202.18,3296.14Z" style={{fill: `${secondRing[2] === "00000000" && secondRing[3] === "00000000" ? "#DF6A33" : "#FFFFFF"}`, stroke: "#787878", strokeWidth: "11.67px"}}/>
                <path d="M1201.42,3294.93L711.258,3596.73C682.56,3549.86 655.699,3501.91 630.768,3452.98L1020.94,3254.18L1201.42,3294.93Z" style={{fill: `${secondRing[2] === "00000000" && secondRing[3] === "00000000" ? "#DF6A33" : "#FFFFFF"}`, stroke: "#787878", strokeWidth: "11.67px"}}/>

                <path d="M1093.6,3083.31L561.331,3302.47C582.382,3353.23 605.381,3403.14 630.313,3452.08L1020.49,3253.27L1093.6,3083.31Z" style={{fill: `${secondRing[3].charAt(1) === "1" || secondRing[3] === "00000000" ? "#DF6A33" : "#FFFFFF"}`, stroke: "#787878", strokeWidth: "11.67px"}}/>
                <path d="M1093.09,3082L561.752,3303.4C540.739,3252.62 521.709,3201.06 504.739,3148.84L921.207,3013.52L1093.09,3082Z" style={{fill: `${secondRing[3].charAt(2) === "1" || secondRing[3] === "00000000" ? "#DF6A33" : "#FFFFFF"}`, stroke: "#787878", strokeWidth: "11.67px"}}/>
                <path d="M1019.7,2856.12L459.7,2989.31C472.55,3042.75 487.458,3095.64 504.428,3147.87L920.896,3012.55L1019.7,2856.12Z" style={{fill: `${secondRing[3].charAt(3) === "1" || secondRing[3] === "00000000" ? "#DF6A33" : "#FFFFFF"}`, stroke: "#787878", strokeWidth: "11.67px"}}/>
                <path d="M1019.35,2854.74L459.919,2990.3C447.109,2936.85 436.379,2882.95 427.788,2828.71L860.296,2760.21L1019.35,2854.74Z" style={{fill: `${secondRing[3].charAt(4) === "1" || secondRing[3] === "00000000" ? "#DF6A33" : "#FFFFFF"}`, stroke: "#787878", strokeWidth: "11.67px"}}/>
                <path d="M982.2,2620.16L408.26,2664.11C412.592,2718.9 419.042,2773.47 427.633,2827.71L860.142,2759.21L982.2,2620.16Z" style={{fill: `${secondRing[3].charAt(5) === "1" || secondRing[3] === "00000000" ? "#DF6A33" : "#FFFFFF"}`, stroke: "#787878", strokeWidth: "11.67px"}}/>
                <path d="M982.083,2618.75L408.333,2665.12C404.042,2610.33 401.875,2555.42 401.875,2500.5L839.775,2500.5L982.083,2618.75Z" style={{fill: `${secondRing[3].charAt(6) === "1" || secondRing[3] === "00000000" ? "#DF6A33" : "#FFFFFF"}`, stroke: "#787878", strokeWidth: "11.67px"}}/>
                <path d="M982.083,2381.25L408.333,2334.88C404.042,2389.67 401.875,2444.58 401.875,2499.5L839.775,2499.5L982.083,2381.25Z" style={{fill: `${secondRing[3].charAt(7) === "1" || secondRing[3] === "00000000" ? "#DF6A33" : "#FFFFFF"}`, stroke: "#787878", strokeWidth: "11.67px"}}/>

                <path d="M982.209,2379.83L408.268,2335.88C412.601,2281.09 419.051,2226.52 427.642,2172.28L860.15,2240.78L982.209,2379.83Z" style={{fill: `${secondRing[3] === "00000000" && secondRing[4] === "00000000" ? "#DF6A33" : "#FFFFFF"}`, stroke: "#787878", strokeWidth: "11.67px"}}/>
                <path d="M1019.36,2145.25L459.929,2009.69C447.119,2063.14 436.388,2117.03 427.797,2171.28L860.306,2239.78L1019.36,2145.25Z" style={{fill: `${secondRing[3] === "00000000" && secondRing[4] === "00000000" ? "#DF6A33" : "#FFFFFF"}`, stroke: "#787878", strokeWidth: "11.67px"}}/>
                <path d="M1019.68,2143.88L459.68,2010.69C472.53,1957.25 487.438,1904.36 504.408,1852.13L920.876,1987.45L1019.68,2143.88Z" style={{fill: `${secondRing[3] === "00000000" && secondRing[4] === "00000000" ? "#DF6A33" : "#FFFFFF"}`, stroke: "#787878", strokeWidth: "11.67px"}}/>

                <path d="M1093.07,1918L561.732,1696.6C540.719,1747.38 521.689,1798.93 504.719,1851.16L921.187,1986.48L1093.07,1918Z" style={{fill: `${secondRing[4].charAt(1) === "1" || secondRing[4] === "00000000" ? "#DF6A33" : "#FFFFFF"}`, stroke: "#787878", strokeWidth: "11.67px"}}/>
                <path d="M1093.61,1916.7L561.341,1697.54C582.392,1646.78 605.391,1596.87 630.323,1547.93L1020.5,1746.74L1093.61,1916.7Z" style={{fill: `${secondRing[4].charAt(2) === "1" || secondRing[4] === "00000000" ? "#DF6A33" : "#FFFFFF"}`, stroke: "#787878", strokeWidth: "11.67px"}}/>
                <path d="M1201.43,1705.09L711.268,1403.29C682.569,1450.16 655.709,1498.11 630.777,1547.04L1020.95,1745.84L1201.43,1705.09Z" style={{fill: `${secondRing[4].charAt(3) === "1" || secondRing[4] === "00000000" ? "#DF6A33" : "#FFFFFF"}`, stroke: "#787878", strokeWidth: "11.67px"}}/>
                <path d="M1202.18,1703.85L710.748,1404.13C739.482,1357.28 770.006,1311.58 802.285,1267.15L1156.55,1524.54L1202.18,1703.85Z" style={{fill: `${secondRing[4].charAt(4) === "1" || secondRing[4] === "00000000" ? "#DF6A33" : "#FFFFFF"}`, stroke: "#787878", strokeWidth: "11.67px"}}/>
                <path d="M1341.78,1511.71L904.865,1136.95C869.188,1178.76 835.158,1221.91 802.879,1266.34L1157.15,1523.73L1341.78,1511.71Z" style={{fill: `${secondRing[4].charAt(5) === "1" || secondRing[4] === "00000000" ? "#DF6A33" : "#FFFFFF"}`, stroke: "#787878", strokeWidth: "11.67px"}}/>
                <path d="M1342.7,1510.64L904.206,1137.73C939.915,1095.95 977.211,1055.59 1016.04,1016.76L1325.69,1326.4L1342.7,1510.64Z" style={{fill: `${secondRing[4].charAt(6) === "1" || secondRing[4] === "00000000" ? "#DF6A33" : "#FFFFFF"}`, stroke: "#787878", strokeWidth: "11.67px"}}/>
                <path d="M1510.64,1342.7L1137.73,904.206C1095.95,939.914 1055.59,977.211 1016.76,1016.04L1326.4,1325.68L1510.64,1342.7Z" style={{fill: `${secondRing[4].charAt(7) === "1" || secondRing[4] === "00000000" ? "#DF6A33" : "#FFFFFF"}`, stroke: "#787878", strokeWidth: "11.67px"}}/>

                <path d="M1511.72,1341.78L1136.96,904.865C1178.77,869.187 1221.92,835.158 1266.35,802.879L1523.74,1157.15L1511.72,1341.78Z" style={{fill: `${secondRing[4] === "00000000" && secondRing[5] === "00000000" ? "#DF6A33" : "#FFFFFF"}`, stroke: "#787878", strokeWidth: "11.67px"}}/>
                <path d="M1703.86,1202.18L1404.14,710.748C1357.29,739.482 1311.59,770.006 1267.16,802.285L1524.55,1156.55L1703.86,1202.18Z" style={{fill: `${secondRing[4] === "00000000" && secondRing[5] === "00000000" ? "#DF6A33" : "#FFFFFF"}`, stroke: "#787878", strokeWidth: "11.67px"}}/>
                <path d="M1705.08,1201.42L1403.28,711.258C1450.15,682.56 1498.1,655.699 1547.03,630.768L1745.83,1020.94L1705.08,1201.42Z" style={{fill: `${secondRing[4] === "00000000" && secondRing[5] === "00000000" ? "#DF6A33" : "#FFFFFF"}`, stroke: "#787878", strokeWidth: "11.67px"}}/>

                <path d="M1916.69,1093.6L1697.53,561.331C1646.77,582.382 1596.86,605.381 1547.92,630.313L1746.73,1020.49L1916.69,1093.6Z" style={{fill: `${secondRing[5].charAt(1) === "1" || secondRing[5] === "00000000" ? "#DF6A33" : "#FFFFFF"}`, stroke: "#787878", strokeWidth: "11.67px"}}/>
                <path d="M1918,1093.09L1696.6,561.752C1747.38,540.739 1798.94,521.709 1851.16,504.739L1986.48,921.207L1918,1093.09Z" style={{fill: `${secondRing[5].charAt(2) === "1" || secondRing[5] === "00000000" ? "#DF6A33" : "#FFFFFF"}`, stroke: "#787878", strokeWidth: "11.67px"}}/>
                <path d="M2143.88,1019.7L2010.69,459.7C1957.25,472.55 1904.36,487.458 1852.13,504.428L1987.45,920.896L2143.88,1019.7Z" style={{fill: `${secondRing[5].charAt(3) === "1" || secondRing[5] === "00000000" ? "#DF6A33" : "#FFFFFF"}`, stroke: "#787878", strokeWidth: "11.67px"}}/>
                <path d="M2145.26,1019.35L2009.7,459.919C2063.15,447.109 2117.05,436.379 2171.29,427.788L2239.79,860.296L2145.26,1019.35Z" style={{fill: `${secondRing[5].charAt(4) === "1" || secondRing[5] === "00000000" ? "#DF6A33" : "#FFFFFF"}`, stroke: "#787878", strokeWidth: "11.67px"}}/>
                <path d="M2379.84,982.2L2335.89,408.26C2281.1,412.592 2226.53,419.042 2172.29,427.633L2240.79,860.142L2379.84,982.2Z" style={{fill: `${secondRing[5].charAt(5) === "1" || secondRing[5] === "00000000" ? "#DF6A33" : "#FFFFFF"}`, stroke: "#787878", strokeWidth: "11.67px"}}/>
                <path d="M2381.25,982.083L2334.88,408.333C2389.67,404.042 2444.58,401.875 2499.5,401.875L2499.5,839.775L2381.25,982.083Z" style={{fill: `${secondRing[5].charAt(6) === "1" || secondRing[5] === "00000000" ? "#DF6A33" : "#FFFFFF"}`, stroke: "#787878", strokeWidth: "11.67px"}}/>
            </g>

            <g id="third-ring">
                <path d="M2567.05,1642.86L2618.75,982.081L2500,839.165L2500,1508.93L2567.05,1642.86Z" style={{fill: `${thirdRing[3].charAt(7) === "1" || thirdRing[3] === "00000000" ? "#DF6A33" : "#FFFFFF"}`, stroke: "#787878", strokeWidth: "11.67px"}}/>

                <path d="M2567.86,1642.94L2620.17,982.209L2759.81,859.628L2655.04,1521.14L2567.86,1642.94Z" style={{fill: `${thirdRing[3] === "00000000" && thirdRing[4] === "00000000" ? "#DF6A33" : "#FFFFFF"}`, stroke: "#787878", strokeWidth: "11.67px"}}/>
                <path d="M2700.31,1663.92L2854.74,1019.36L2759.81,859.629L2655.03,1521.15L2700.31,1663.92Z" style={{fill: `${thirdRing[3] === "00000000" && thirdRing[4] === "00000000" ? "#DF6A33" : "#FFFFFF"}`, stroke: "#787878", strokeWidth: "11.67px"}}/>
                <path d="M2701.1,1664.09L2856.13,1019.68L3013.23,920.45L2806.26,1557.43L2701.1,1664.09Z" style={{fill: `${thirdRing[3] === "00000000" && thirdRing[4] === "00000000" ? "#DF6A33" : "#FFFFFF"}`, stroke: "#787878", strokeWidth: "11.67px"}}/>

                <path d="M2828.64,1705.53L3082,1093.07L3013.22,920.448L2806.26,1557.43L2828.64,1705.53Z" style={{fill: `${thirdRing[4].charAt(1) === "1" || thirdRing[4] === "00000000" ? "#DF6A33" : "#FFFFFF"}`, stroke: "#787878", strokeWidth: "11.67px"}}/>
                <path d="M2829.39,1705.84L3083.32,1093.61L3254.01,1020.18L2949.94,1616.95L2829.39,1705.84Z" style={{fill: `${thirdRing[4].charAt(2) === "1" || thirdRing[4] === "00000000" ? "#DF6A33" : "#FFFFFF"}`, stroke: "#787878", strokeWidth: "11.67px"}}/>
                <path d="M2948.88,1766.72L3294.93,1201.43L3254,1020.18L2949.94,1616.94L2948.88,1766.72Z" style={{fill: `${thirdRing[4].charAt(3) === "1" || thirdRing[4] === "00000000" ? "#DF6A33" : "#FFFFFF"}`, stroke: "#787878", strokeWidth: "11.67px"}}/>
                <path d="M2949.57,1767.15L3296.14,1202.18L3476.22,1156.36L3082.54,1698.21L2949.57,1767.15Z" style={{fill: `${thirdRing[4].charAt(4) === "1" || thirdRing[4] === "00000000" ? "#DF6A33" : "#FFFFFF"}`, stroke: "#787878", strokeWidth: "11.67px"}}/>
                <path d="M3058.06,1845.97L3488.28,1341.77L3476.21,1156.35L3082.54,1698.2L3058.06,1845.97Z" style={{fill: `${thirdRing[4].charAt(5) === "1" || thirdRing[4] === "00000000" ? "#DF6A33" : "#FFFFFF"}`, stroke: "#787878", strokeWidth: "11.67px"}}/>
                <path d="M3058.67,1846.5L3489.36,1342.7L3674.38,1325.62L3200.79,1799.21L3058.67,1846.5Z" style={{fill: `${thirdRing[4].charAt(6) === "1" || thirdRing[4] === "00000000" ? "#DF6A33" : "#FFFFFF"}`, stroke: "#787878", strokeWidth: "11.67px"}}/>
                <path d="M3153.5,1941.33L3657.3,1510.64L3674.38,1325.62L3200.79,1799.21L3153.5,1941.33Z" style={{fill: `${thirdRing[4].charAt(7) === "1" || thirdRing[4] === "00000000" ? "#DF6A33" : "#FFFFFF"}`, stroke: "#787878", strokeWidth: "11.67px"}}/>

                <path d="M3154.03,1941.94L3658.23,1511.72L3843.65,1523.79L3301.8,1917.46L3154.03,1941.94Z" style={{fill: `${thirdRing[4] === "00000000" && thirdRing[5] === "00000000" ? "#DF6A33" : "#FFFFFF"}`, stroke: "#787878", strokeWidth: "11.67px"}}/>
                <path d="M3232.85,2050.44L3797.82,1703.87L3843.64,1523.79L3301.79,1917.47L3232.85,2050.44Z" style={{fill: `${thirdRing[4] === "00000000" && thirdRing[5] === "00000000" ? "#DF6A33" : "#FFFFFF"}`, stroke: "#787878", strokeWidth: "11.67px"}}/>
                <path d="M3233.27,2051.12L3798.56,1705.07L3979.81,1746L3383.05,2050.06L3233.27,2051.12Z" style={{fill: `${thirdRing[4] === "00000000" && thirdRing[5] === "00000000" ? "#DF6A33" : "#FFFFFF"}`, stroke: "#787878", strokeWidth: "11.67px"}}/>

                <path d="M3294.16,2170.62L3906.39,1916.69L3979.82,1746.01L3383.05,2050.07L3294.16,2170.62Z" style={{fill: `${thirdRing[5].charAt(1) === "1" || thirdRing[5] === "00000000" ? "#DF6A33" : "#FFFFFF"}`, stroke: "#787878", strokeWidth: "11.67px"}}/>
                <path d="M3294.47,2171.36L3906.93,1918L4079.55,1986.78L3442.57,2193.74L3294.47,2171.36Z" style={{fill: `${thirdRing[5].charAt(2) === "1" || thirdRing[5] === "00000000" ? "#DF6A33" : "#FFFFFF"}`, stroke: "#787878", strokeWidth: "11.67px"}}/>
                <path d="M3335.91,2298.9L3980.32,2143.87L4079.55,1986.77L3442.57,2193.74L3335.91,2298.9Z" style={{fill: `${thirdRing[5].charAt(3) === "1" || thirdRing[5] === "00000000" ? "#DF6A33" : "#FFFFFF"}`, stroke: "#787878", strokeWidth: "11.67px"}}/>
                <path d="M3336.09,2299.69L3980.65,2145.26L4140.38,2240.19L3478.86,2344.97L3336.09,2299.69Z" style={{fill: `${thirdRing[5].charAt(4) === "1" || thirdRing[5] === "00000000" ? "#DF6A33" : "#FFFFFF"}`, stroke: "#787878", strokeWidth: "11.67px"}}/>
                <path d="M3357.07,2432.14L4017.8,2379.83L4140.38,2240.19L3478.87,2344.96L3357.07,2432.14Z" style={{fill: `${thirdRing[5].charAt(5) === "1" || thirdRing[5] === "00000000" ? "#DF6A33" : "#FFFFFF"}`, stroke: "#787878", strokeWidth: "11.67px"}}/>
                <path d="M3357.14,2432.95L4017.92,2381.25L4160.84,2500L3491.07,2500L3357.14,2432.95Z" style={{fill: `${thirdRing[5].charAt(6) === "1" || thirdRing[5] === "00000000" ? "#DF6A33" : "#FFFFFF"}`, stroke: "#787878", strokeWidth: "11.67px"}}/>
                <path d="M3357.14,2567.05L4017.92,2618.75L4160.84,2500L3491.07,2500L3357.14,2567.05Z" style={{fill: `${thirdRing[5].charAt(7) === "1" || thirdRing[5] === "00000000" ? "#DF6A33" : "#FFFFFF"}`, stroke: "#787878", strokeWidth: "11.67px"}}/>

                <path d="M3357.07,2567.86L4017.8,2620.17L4140.38,2759.81L3478.87,2655.04L3357.07,2567.86Z" style={{fill: `${thirdRing[5] === "00000000" && thirdRing[6] === "00000000" ? "#DF6A33" : "#FFFFFF"}`, stroke: "#787878", strokeWidth: "11.67px"}}/>
                <path d="M3336.09,2700.31L3980.65,2854.74L4140.38,2759.81L3478.86,2655.03L3336.09,2700.31Z" style={{fill: `${thirdRing[5] === "00000000" && thirdRing[6] === "00000000" ? "#DF6A33" : "#FFFFFF"}`, stroke: "#787878", strokeWidth: "11.67px"}}/>
                <path d="M3335.91,2701.1L3980.32,2856.13L4079.55,3013.23L3442.57,2806.26L3335.91,2701.1Z" style={{fill: `${thirdRing[5] === "00000000" && thirdRing[6] === "00000000" ? "#DF6A33" : "#FFFFFF"}`, stroke: "#787878", strokeWidth: "11.67px"}}/>

                <path d="M3294.47,2828.64L3906.93,3082L4079.55,3013.22L3442.57,2806.26L3294.47,2828.64Z" style={{fill: `${thirdRing[6].charAt(1) === "1" || thirdRing[6] === "00000000" ? "#DF6A33" : "#FFFFFF"}`, stroke: "#787878", strokeWidth: "11.67px"}}/>
                <path d="M3294.16,2829.39L3906.39,3083.32L3979.82,3254.01L3383.05,2949.94L3294.16,2829.39Z" style={{fill: `${thirdRing[6].charAt(2) === "1" || thirdRing[6] === "00000000" ? "#DF6A33" : "#FFFFFF"}`, stroke: "#787878", strokeWidth: "11.67px"}}/>
                <path d="M3233.28,2948.88L3798.57,3294.93L3979.82,3254L3383.06,2949.94L3233.28,2948.88Z" style={{fill: `${thirdRing[6].charAt(3) === "1" || thirdRing[6] === "00000000" ? "#DF6A33" : "#FFFFFF"}`, stroke: "#787878", strokeWidth: "11.67px"}}/>
                <path d="M3232.85,2949.57L3797.82,3296.14L3843.64,3476.22L3301.79,3082.54L3232.85,2949.57Z" style={{fill: `${thirdRing[6].charAt(4) === "1" || thirdRing[6] === "00000000" ? "#DF6A33" : "#FFFFFF"}`, stroke: "#787878", strokeWidth: "11.67px"}}/>
                <path d="M3154.03,3058.06L3658.23,3488.28L3843.65,3476.21L3301.8,3082.54L3154.03,3058.06Z" style={{fill: `${thirdRing[6].charAt(5) === "1" || thirdRing[6] === "00000000" ? "#DF6A33" : "#FFFFFF"}`, stroke: "#787878", strokeWidth: "11.67px"}}/>
                <path d="M3153.5,3058.67L3657.3,3489.36L3674.38,3674.38L3200.79,3200.79L3153.5,3058.67Z" style={{fill: `${thirdRing[6].charAt(6) === "1" || thirdRing[6] === "00000000" ? "#DF6A33" : "#FFFFFF"}`, stroke: "#787878", strokeWidth: "11.67px"}}/>
                <path d="M3058.68,3153.5L3489.37,3657.3L3674.39,3674.38L3200.8,3200.79L3058.68,3153.5Z" style={{fill: `${thirdRing[6].charAt(7) === "1" || thirdRing[6] === "00000000" ? "#DF6A33" : "#FFFFFF"}`, stroke: "#787878", strokeWidth: "11.67px"}}/>

                <path d="M3058.06,3154.03L3488.28,3658.23L3476.21,3843.65L3082.54,3301.8L3058.06,3154.03Z" style={{fill: `${thirdRing[6] === "00000000" && thirdRing[7] === "00000000" ? "#DF6A33" : "#FFFFFF"}`, stroke: "#787878", strokeWidth: "11.67px"}}/>
                <path d="M2949.56,3232.85L3296.13,3797.82L3476.21,3843.64L3082.53,3301.79L2949.56,3232.85Z" style={{fill: `${thirdRing[6] === "00000000" && thirdRing[7] === "00000000" ? "#DF6A33" : "#FFFFFF"}`, stroke: "#787878", strokeWidth: "11.67px"}}/>
                <path d="M2948.88,3233.27L3294.93,3798.56L3254,3979.81L2949.94,3383.05L2948.88,3233.27Z" style={{fill: `${thirdRing[6] === "00000000" && thirdRing[7] === "00000000" ? "#DF6A33" : "#FFFFFF"}`, stroke: "#787878", strokeWidth: "11.67px"}}/>

                <path d="M2829.39,3294.16L3083.32,3906.39L3254.01,3979.82L2949.94,3383.05L2829.39,3294.16Z" style={{fill: `${thirdRing[7].charAt(1) === "1" || thirdRing[7] === "00000000" ? "#DF6A33" : "#FFFFFF"}`, stroke: "#787878", strokeWidth: "11.67px"}}/>
                <path d="M2828.64,3294.47L3082,3906.93L3013.22,4079.55L2806.26,3442.57L2828.64,3294.47Z" style={{fill: `${thirdRing[7].charAt(2) === "1" || thirdRing[7] === "00000000" ? "#DF6A33" : "#FFFFFF"}`, stroke: "#787878", strokeWidth: "11.67px"}}/>
                <path d="M2701.1,3335.91L2856.13,3980.32L3013.23,4079.55L2806.26,3442.57L2701.1,3335.91Z" style={{fill: `${thirdRing[7].charAt(3) === "1" || thirdRing[7] === "00000000" ? "#DF6A33" : "#FFFFFF"}`, stroke: "#787878", strokeWidth: "11.67px"}}/>
                <path d="M2700.31,3336.09L2854.74,3980.65L2759.81,4140.38L2655.03,3478.86L2700.31,3336.09Z" style={{fill: `${thirdRing[7].charAt(4) === "1" || thirdRing[7] === "00000000" ? "#DF6A33" : "#FFFFFF"}`, stroke: "#787878", strokeWidth: "11.67px"}}/>
                <path d="M2567.85,3357.07L2620.16,4017.8L2759.8,4140.38L2655.03,3478.87L2567.85,3357.07Z" style={{fill: `${thirdRing[7].charAt(5) === "1" || thirdRing[7] === "00000000" ? "#DF6A33" : "#FFFFFF"}`, stroke: "#787878", strokeWidth: "11.67px"}}/>
                <path d="M2567.05,3357.14L2618.75,4017.92L2500,4160.84L2500,3491.07L2567.05,3357.14Z" style={{fill: `${thirdRing[7].charAt(6) === "1" || thirdRing[7] === "00000000" ? "#DF6A33" : "#FFFFFF"}`, stroke: "#787878", strokeWidth: "11.67px"}}/>
                <path d="M2432.95,3357.14L2381.25,4017.92L2500,4160.84L2500,3491.07L2432.95,3357.14Z" style={{fill: `${thirdRing[7].charAt(7) === "1" || thirdRing[7] === "00000000" ? "#DF6A33" : "#FFFFFF"}`, stroke: "#787878", strokeWidth: "11.67px"}}/>

                <path d="M2432.14,3357.07L2379.83,4017.8L2240.19,4140.38L2344.96,3478.87L2432.14,3357.07Z" style={{fill: `${thirdRing[7] === "00000000" && thirdRing[0] === "00000000" ? "#DF6A33" : "#FFFFFF"}`, stroke: "#787878", strokeWidth: "11.67px"}}/>
                <path d="M2299.68,3336.09L2145.25,3980.65L2240.18,4140.38L2344.96,3478.86L2299.68,3336.09Z" style={{fill: `${thirdRing[7] === "00000000" && thirdRing[0] === "00000000" ? "#DF6A33" : "#FFFFFF"}`, stroke: "#787878", strokeWidth: "11.67px"}}/>
                <path d="M2298.9,3335.91L2143.87,3980.32L1986.77,4079.55L2193.74,3442.57L2298.9,3335.91Z" style={{fill: `${thirdRing[7] === "00000000" && thirdRing[0] === "00000000" ? "#DF6A33" : "#FFFFFF"}`, stroke: "#787878", strokeWidth: "11.67px"}}/>

                <path d="M2171.36,3294.47L1918,3906.93L1986.78,4079.55L2193.74,3442.57L2171.36,3294.47Z" style={{fill: `${thirdRing[0].charAt(1) === "1" || thirdRing[0] === "00000000" ? "#DF6A33" : "#FFFFFF"}`, stroke: "#787878", strokeWidth: "11.67px"}}/>
                <path d="M2170.62,3294.16L1916.69,3906.39L1746.01,3979.82L2050.07,3383.05L2170.62,3294.16Z" style={{fill: `${thirdRing[0].charAt(2) === "1" || thirdRing[0] === "00000000" ? "#DF6A33" : "#FFFFFF"}`, stroke: "#787878", strokeWidth: "11.67px"}}/>
                <path d="M2051.13,3233.28L1705.08,3798.57L1746.01,3979.82L2050.07,3383.06L2051.13,3233.28Z" style={{fill: `${thirdRing[0].charAt(3) === "1" || thirdRing[0] === "00000000" ? "#DF6A33" : "#FFFFFF"}`, stroke: "#787878", strokeWidth: "11.67px"}}/>
                <path d="M2050.43,3232.85L1703.86,3797.82L1523.78,3843.64L1917.46,3301.79L2050.43,3232.85Z" style={{fill: `${thirdRing[0].charAt(4) === "1" || thirdRing[0] === "00000000" ? "#DF6A33" : "#FFFFFF"}`, stroke: "#787878", strokeWidth: "11.67px"}}/>
                <path d="M1941.93,3154.03L1511.71,3658.23L1523.78,3843.65L1917.45,3301.8L1941.93,3154.03Z" style={{fill: `${thirdRing[0].charAt(5) === "1" || thirdRing[0] === "00000000" ? "#DF6A33" : "#FFFFFF"}`, stroke: "#787878", strokeWidth: "11.67px"}}/>
                <path d="M1941.33,3153.5L1510.64,3657.3L1325.62,3674.38L1799.21,3200.79L1941.33,3153.5Z" style={{fill: `${thirdRing[0].charAt(6) === "1" || thirdRing[0] === "00000000" ? "#DF6A33" : "#FFFFFF"}`, stroke: "#787878", strokeWidth: "11.67px"}}/>
                <path d="M1846.5,3058.68L1342.7,3489.37L1325.62,3674.39L1799.21,3200.8L1846.5,3058.68Z" style={{fill: `${thirdRing[0].charAt(7) === "1" || thirdRing[0] === "00000000" ? "#DF6A33" : "#FFFFFF"}`, stroke: "#787878", strokeWidth: "11.67px"}}/>

                <path d="M1845.98,3058.06L1341.78,3488.28L1156.36,3476.21L1698.21,3082.54L1845.98,3058.06Z" style={{fill: `${thirdRing[0] === "00000000" && thirdRing[1] === "00000000" ? "#DF6A33" : "#FFFFFF"}`, stroke: "#787878", strokeWidth: "11.67px"}}/>
                <path d="M1767.15,2949.56L1202.18,3296.13L1156.36,3476.21L1698.21,3082.53L1767.15,2949.56Z" style={{fill: `${thirdRing[0] === "00000000" && thirdRing[1] === "00000000" ? "#DF6A33" : "#FFFFFF"}`, stroke: "#787878", strokeWidth: "11.67px"}}/>
                <path d="M1766.71,2948.88L1201.42,3294.93L1020.17,3254L1616.93,2949.94L1766.71,2948.88Z" style={{fill: `${thirdRing[0] === "00000000" && thirdRing[1] === "00000000" ? "#DF6A33" : "#FFFFFF"}`, stroke: "#787878", strokeWidth: "11.67px"}}/>

                <path d="M1705.83,2829.39L1093.6,3083.32L1020.17,3254.01L1616.94,2949.94L1705.83,2829.39Z" style={{fill: `${thirdRing[1].charAt(1) === "1" || thirdRing[1] === "00000000" ? "#DF6A33" : "#FFFFFF"}`, stroke: "#787878", strokeWidth: "11.67px"}}/>
                <path d="M1705.55,2828.64L1093.09,3082L920.468,3013.22L1557.45,2806.26L1705.55,2828.64Z" style={{fill: `${thirdRing[1].charAt(2) === "1" || thirdRing[1] === "00000000" ? "#DF6A33" : "#FFFFFF"}`, stroke: "#787878", strokeWidth: "11.67px"}}/>
                <path d="M1664.11,2701.1L1019.7,2856.13L920.47,3013.23L1557.45,2806.26L1664.11,2701.1Z" style={{fill: `${thirdRing[1].charAt(3) === "1" || thirdRing[1] === "00000000" ? "#DF6A33" : "#FFFFFF"}`, stroke: "#787878", strokeWidth: "11.67px"}}/>
                <path d="M1663.91,2700.31L1019.35,2854.74L859.62,2759.81L1521.14,2655.03L1663.91,2700.31Z" style={{fill: `${thirdRing[1].charAt(4) === "1" || thirdRing[1] === "00000000" ? "#DF6A33" : "#FFFFFF"}`, stroke: "#787878", strokeWidth: "11.67px"}}/>
                <path d="M1642.93,2567.85L982.199,2620.16L859.619,2759.8L1521.13,2655.03L1642.93,2567.85Z" style={{fill: `${thirdRing[1].charAt(5) === "1" || thirdRing[1] === "00000000" ? "#DF6A33" : "#FFFFFF"}`, stroke: "#787878", strokeWidth: "11.67px"}}/>
                <path d="M1642.86,2567.05L982.081,2618.75L839.165,2500L1508.93,2500L1642.86,2567.05Z" style={{fill: `${thirdRing[1].charAt(6) === "1" || thirdRing[1] === "00000000" ? "#DF6A33" : "#FFFFFF"}`, stroke: "#787878", strokeWidth: "11.67px"}}/>
                <path d="M1642.86,2432.95L982.081,2381.25L839.165,2500L1508.93,2500L1642.86,2432.95Z" style={{fill: `${thirdRing[1].charAt(7) === "1" || thirdRing[1] === "00000000" ? "#DF6A33" : "#FFFFFF"}`, stroke: "#787878", strokeWidth: "11.67px"}}/>

                <path d="M1642.94,2432.14L982.209,2379.83L859.628,2240.19L1521.14,2344.96L1642.94,2432.14Z" style={{fill: `${thirdRing[1] === "00000000" && thirdRing[2] === "00000000" ? "#DF6A33" : "#FFFFFF"}`, stroke: "#787878", strokeWidth: "11.67px"}}/>
                <path d="M1663.92,2299.68L1019.36,2145.25L859.629,2240.18L1521.15,2344.96L1663.92,2299.68Z" style={{fill: `${thirdRing[1] === "00000000" && thirdRing[2] === "00000000" ? "#DF6A33" : "#FFFFFF"}`, stroke: "#787878", strokeWidth: "11.67px"}}/>
                <path d="M1664.09,2298.9L1019.68,2143.87L920.45,1986.77L1557.43,2193.74L1664.09,2298.9Z" style={{fill: `${thirdRing[1] === "00000000" && thirdRing[2] === "00000000" ? "#DF6A33" : "#FFFFFF"}`, stroke: "#787878", strokeWidth: "11.67px"}}/>

                <path d="M1705.53,2171.36L1093.07,1918L920.448,1986.78L1557.43,2193.74L1705.53,2171.36Z" style={{fill: `${thirdRing[2].charAt(1) === "1" || thirdRing[2] === "00000000" ? "#DF6A33" : "#FFFFFF"}`, stroke: "#787878", strokeWidth: "11.67px"}}/>
                <path d="M1705.84,2170.62L1093.61,1916.69L1020.18,1746.01L1616.95,2050.07L1705.84,2170.62Z" style={{fill: `${thirdRing[2].charAt(2) === "1" || thirdRing[2] === "00000000" ? "#DF6A33" : "#FFFFFF"}`, stroke: "#787878", strokeWidth: "11.67px"}}/>
                <path d="M1766.72,2051.13L1201.43,1705.08L1020.18,1746.01L1616.94,2050.07L1766.72,2051.13Z" style={{fill: `${thirdRing[2].charAt(3) === "1" || thirdRing[2] === "00000000" ? "#DF6A33" : "#FFFFFF"}`, stroke: "#787878", strokeWidth: "11.67px"}}/>
                <path d="M1767.15,2050.43L1202.18,1703.86L1156.36,1523.78L1698.21,1917.46L1767.15,2050.43Z" style={{fill: `${thirdRing[2].charAt(4) === "1" || thirdRing[2] === "00000000" ? "#DF6A33" : "#FFFFFF"}`, stroke: "#787878", strokeWidth: "11.67px"}}/>
                <path d="M1845.97,1941.93L1341.77,1511.71L1156.35,1523.78L1698.2,1917.45L1845.97,1941.93Z" style={{fill: `${thirdRing[2].charAt(5) === "1" || thirdRing[2] === "00000000" ? "#DF6A33" : "#FFFFFF"}`, stroke: "#787878", strokeWidth: "11.67px"}}/>
                <path d="M1846.5,1941.33L1342.7,1510.64L1325.62,1325.62L1799.21,1799.21L1846.5,1941.33Z" style={{fill: `${thirdRing[2].charAt(6) === "1" || thirdRing[2] === "00000000" ? "#DF6A33" : "#FFFFFF"}`, stroke: "#787878", strokeWidth: "11.67px"}}/>
                <path d="M1941.33,1846.5L1510.64,1342.7L1325.62,1325.62L1799.21,1799.21L1941.33,1846.5Z" style={{fill: `${thirdRing[2].charAt(7) === "1" || thirdRing[2] === "00000000" ? "#DF6A33" : "#FFFFFF"}`, stroke: "#787878", strokeWidth: "11.67px"}}/>

                <path d="M1941.94,1845.98L1511.72,1341.78L1523.79,1156.36L1917.46,1698.21L1941.94,1845.98Z" style={{fill: `${thirdRing[2] === "00000000" && thirdRing[3] === "00000000" ? "#DF6A33" : "#FFFFFF"}`, stroke: "#787878", strokeWidth: "11.67px"}}/>
                <path d="M2050.44,1767.15L1703.87,1202.18L1523.79,1156.36L1917.47,1698.21L2050.44,1767.15Z" style={{fill: `${thirdRing[2] === "00000000" && thirdRing[3] === "00000000" ? "#DF6A33" : "#FFFFFF"}`, stroke: "#787878", strokeWidth: "11.67px"}}/>
                <path d="M2051.12,1766.71L1705.07,1201.42L1746,1020.17L2050.06,1616.93L2051.12,1766.71Z" style={{fill: `${thirdRing[2] === "00000000" && thirdRing[3] === "00000000" ? "#DF6A33" : "#FFFFFF"}`, stroke: "#787878", strokeWidth: "11.67px"}}/>

                <path d="M2170.62,1705.83L1916.69,1093.6L1746.01,1020.17L2050.07,1616.94L2170.62,1705.83Z" style={{fill: `${thirdRing[3].charAt(1) === "1" || thirdRing[3] === "00000000" ? "#DF6A33" : "#FFFFFF"}`, stroke: "#787878", strokeWidth: "11.67px"}}/>
                <path d="M2171.36,1705.55L1918,1093.09L1986.78,920.468L2193.74,1557.45L2171.36,1705.55Z" style={{fill: `${thirdRing[3].charAt(2) === "1" || thirdRing[3] === "00000000" ? "#DF6A33" : "#FFFFFF"}`, stroke: "#787878", strokeWidth: "11.67px"}}/>
                <path d="M2298.9,1664.11L2143.87,1019.7L1986.77,920.47L2193.74,1557.45L2298.9,1664.11Z" style={{fill: `${thirdRing[3].charAt(3) === "1" || thirdRing[3] === "00000000" ? "#DF6A33" : "#FFFFFF"}`, stroke: "#787878", strokeWidth: "11.67px"}}/>
                <path d="M2299.69,1663.91L2145.26,1019.35L2240.19,859.62L2344.97,1521.14L2299.69,1663.91Z" style={{fill: `${thirdRing[3].charAt(4) === "1" || thirdRing[3] === "00000000" ? "#DF6A33" : "#FFFFFF"}`, stroke: "#787878", strokeWidth: "11.67px"}}/>
                <path d="M2432.14,1642.93L2379.83,982.199L2240.19,859.619L2344.96,1521.13L2432.14,1642.93Z" style={{fill: `${thirdRing[3].charAt(5) === "1" || thirdRing[3] === "00000000" ? "#DF6A33" : "#FFFFFF"}`, stroke: "#787878", strokeWidth: "11.67px"}}/>
                <path d="M2432.95,1642.86L2381.25,982.081L2500,839.165L2500,1508.93L2432.95,1642.86Z" style={{fill: `${thirdRing[3].charAt(6) === "1" || thirdRing[3] === "00000000" ? "#DF6A33" : "#FFFFFF"}`, stroke: "#787878", strokeWidth: "11.67px"}}/>
            </g>

            <g id="inner-ring">
                <path d="M2524.39,2188.44L2567.09,1642.91L2500,1508.93L2500,2187.5C2508.21,2187.5 2516.35,2187.82 2524.39,2188.44Z" style={{fill: `${innerRing[7].charAt(7) === "1" || innerRing[7] === "00000000" ? "#DF6A33" : "#FFFFFF"}`, stroke: "#787878", strokeWidth: "11.67px"}}/>

                <path d="M2524.65,2188.48L2567.82,1642.99L2655.04,1521.15L2548.88,2191.37C2540.78,2190.08 2532.69,2189.12 2524.65,2188.48Z" style={{fill: `${innerRing[7] === "00000000" && innerRing[0] === "00000000" ? "#DF6A33" : "#FFFFFF"}`, stroke: "#787878", strokeWidth: "11.67px"}}/>
                <path d="M2572.82,2196.11L2700.33,1663.98L2655.03,1521.15L2548.88,2191.37C2556.99,2192.65 2564.97,2194.24 2572.82,2196.11Z" style={{fill: `${innerRing[7] === "00000000" && innerRing[0] === "00000000" ? "#DF6A33" : "#FFFFFF"}`, stroke: "#787878", strokeWidth: "11.67px"}}/>
                <path d="M2573.09,2196.15L2701.06,1664.13L2806.26,1557.43L2596.58,2202.79C2588.77,2200.25 2580.93,2198.04 2573.09,2196.15Z" style={{fill: `${innerRing[7] === "00000000" && innerRing[0] === "00000000" ? "#DF6A33" : "#FFFFFF"}`, stroke: "#787878", strokeWidth: "11.67px"}}/>

                <path d="M2619.47,2211.23L2828.65,1705.6L2806.26,1557.44L2596.57,2202.8C2604.37,2205.34 2612.01,2208.15 2619.47,2211.23Z" style={{fill: `${innerRing[0].charAt(1) === "1" || innerRing[0] === "00000000" ? "#DF6A33" : "#FFFFFF"}`, stroke: "#787878", strokeWidth: "11.67px"}}/>
                <path d="M2619.72,2211.32L2829.34,1705.87L2949.94,1616.94L2641.88,2221.55C2634.56,2217.83 2627.17,2214.41 2619.72,2211.32Z" style={{fill: `${innerRing[0].charAt(2) === "1" || innerRing[0] === "00000000" ? "#DF6A33" : "#FFFFFF"}`, stroke: "#787878", strokeWidth: "11.67px"}}/>
                <path d="M2663.18,2233.47L2948.89,1766.79L2949.94,1616.95L2641.88,2221.56C2649.19,2225.28 2656.3,2229.26 2663.18,2233.47Z" style={{fill: `${innerRing[0].charAt(3) === "1" || innerRing[0] === "00000000" ? "#DF6A33" : "#FFFFFF"}`, stroke: "#787878", strokeWidth: "11.67px"}}/>
                <path d="M2663.4,2233.61L2949.51,1767.17L3082.54,1698.21L2683.68,2247.18C2677.04,2242.36 2670.27,2237.83 2663.4,2233.61Z" style={{fill: `${innerRing[0].charAt(4) === "1" || innerRing[0] === "00000000" ? "#DF6A33" : "#FFFFFF"}`, stroke: "#787878", strokeWidth: "11.67px"}}/>
                <path d="M2702.86,2262.28L3058.05,1846.04L3082.54,1698.21L2683.68,2247.18C2690.32,2252.01 2696.72,2257.05 2702.86,2262.28Z" style={{fill: `${innerRing[0].charAt(5) === "1" || innerRing[0] === "00000000" ? "#DF6A33" : "#FFFFFF"}`, stroke: "#787878", strokeWidth: "11.67px"}}/>
                <path d="M2703.06,2262.45L3058.61,1846.52L3200.79,1799.21L2720.97,2279.03C2715.17,2273.22 2709.19,2267.7 2703.06,2262.45Z" style={{fill: `${innerRing[0].charAt(6) === "1" || innerRing[0] === "00000000" ? "#DF6A33" : "#FFFFFF"}`, stroke: "#787878", strokeWidth: "11.67px"}}/>
                <path d="M2737.55,2296.94L3153.49,1941.39L3200.79,1799.21L2720.97,2279.03C2726.78,2284.83 2732.31,2290.81 2737.55,2296.94Z" style={{fill: `${innerRing[0].charAt(7) === "1" || innerRing[0] === "00000000" ? "#DF6A33" : "#FFFFFF"}`, stroke: "#787878", strokeWidth: "11.67px"}}/>

                <path d="M2737.72,2297.14L3153.96,1941.95L3301.79,1917.46L2752.82,2316.32C2747.99,2309.68 2742.95,2303.28 2737.72,2297.14Z" style={{fill: `${innerRing[0] === "00000000" && innerRing[1] === "00000000" ? "#DF6A33" : "#FFFFFF"}`, stroke: "#787878", strokeWidth: "11.67px"}}/>
                <path d="M2766.39,2336.6L3232.82,2050.49L3301.79,1917.46L2752.82,2316.32C2757.64,2322.96 2762.17,2329.73 2766.39,2336.6Z" style={{fill: `${innerRing[0] === "00000000" && innerRing[1] === "00000000" ? "#DF6A33" : "#FFFFFF"}`, stroke: "#787878", strokeWidth: "11.67px"}}/>
                <path d="M2766.53,2336.83L3233.21,2051.12L3383.05,2050.07L2778.44,2358.13C2774.72,2350.82 2770.74,2343.71 2766.53,2336.83Z" style={{fill: `${innerRing[0] === "00000000" && innerRing[1] === "00000000" ? "#DF6A33" : "#FFFFFF"}`, stroke: "#787878", strokeWidth: "11.67px"}}/>

                <path d="M2788.67,2380.29L3294.12,2170.67L3383.05,2050.07L2778.44,2358.13C2782.16,2365.45 2785.58,2372.84 2788.67,2380.29Z" style={{fill: `${innerRing[1].charAt(1) === "1" || innerRing[1] === "00000000" ? "#DF6A33" : "#FFFFFF"}`, stroke: "#787878", strokeWidth: "11.67px"}}/>
                <path d="M2788.78,2380.53L3294.41,2171.35L3442.57,2193.74L2797.21,2403.43C2794.68,2395.63 2791.86,2387.99 2788.78,2380.53Z" style={{fill: `${innerRing[1].charAt(2) === "1" || innerRing[1] === "00000000" ? "#DF6A33" : "#FFFFFF"}`, stroke: "#787878", strokeWidth: "11.67px"}}/>
                <path d="M2803.85,2426.92L3335.87,2298.95L3442.57,2193.74L2797.21,2403.43C2799.75,2411.24 2801.96,2419.08 2803.85,2426.92Z" style={{fill: `${innerRing[1].charAt(3) === "1" || innerRing[1] === "00000000" ? "#DF6A33" : "#FFFFFF"}`, stroke: "#787878", strokeWidth: "11.67px"}}/>
                <path d="M2803.91,2427.18L3336.04,2299.67L3478.87,2344.97L2808.66,2451.12C2807.37,2443.01 2805.79,2435.03 2803.91,2427.18Z" style={{fill: `${innerRing[1].charAt(4) === "1" || innerRing[1] === "00000000" ? "#DF6A33" : "#FFFFFF"}`, stroke: "#787878", strokeWidth: "11.67px"}}/>
                <path d="M2811.54,2475.35L3357.03,2432.18L3478.87,2344.96L2808.66,2451.12C2809.94,2459.22 2810.9,2467.31 2811.54,2475.35Z" style={{fill: `${innerRing[1].charAt(5) === "1" || innerRing[1] === "00000000" ? "#DF6A33" : "#FFFFFF"}`, stroke: "#787878", strokeWidth: "11.67px"}}/>
                <path d="M2811.56,2475.61L3357.09,2432.91L3491.07,2500L2812.5,2500C2812.5,2491.79 2812.18,2483.65 2811.56,2475.61Z" style={{fill: `${innerRing[1].charAt(6) === "1" || innerRing[1] === "00000000" ? "#DF6A33" : "#FFFFFF"}`, stroke: "#787878", strokeWidth: "11.67px"}}/>
                <path d="M2811.56,2524.39L3357.09,2567.09L3491.07,2500L2812.5,2500C2812.5,2508.21 2812.18,2516.35 2811.56,2524.39Z" style={{fill: `${innerRing[1].charAt(7) === "1" || innerRing[1] === "00000000" ? "#DF6A33" : "#FFFFFF"}`, stroke: "#787878", strokeWidth: "11.67px"}}/>

                <path d="M2811.54,2524.65L3357.03,2567.82L3478.87,2655.04L2808.66,2548.88C2809.94,2540.78 2810.9,2532.69 2811.54,2524.65Z" style={{fill: `${innerRing[1] === "00000000" && innerRing[2] === "00000000" ? "#DF6A33" : "#FFFFFF"}`, stroke: "#787878", strokeWidth: "11.67px"}}/>
                <path d="M2803.91,2572.82L3336.04,2700.33L3478.87,2655.03L2808.66,2548.88C2807.37,2556.99 2805.79,2564.97 2803.91,2572.82Z" style={{fill: `${innerRing[1] === "00000000" && innerRing[2] === "00000000" ? "#DF6A33" : "#FFFFFF"}`, stroke: "#787878", strokeWidth: "11.67px"}}/>
                <path d="M2803.85,2573.09L3335.87,2701.06L3442.57,2806.26L2797.21,2596.58C2799.75,2588.77 2801.96,2580.93 2803.85,2573.09Z" style={{fill: `${innerRing[1] === "00000000" && innerRing[2] === "00000000" ? "#DF6A33" : "#FFFFFF"}`, stroke: "#787878", strokeWidth: "11.67px"}}/>

                <path d="M2788.77,2619.47L3294.4,2828.65L3442.56,2806.26L2797.2,2596.57C2794.66,2604.37 2791.85,2612.01 2788.77,2619.47Z" style={{fill: `${innerRing[2].charAt(1) === "1" || innerRing[2] === "00000000" ? "#DF6A33" : "#FFFFFF"}`, stroke: "#787878", strokeWidth: "11.67px"}}/>
                <path d="M2788.68,2619.72L3294.13,2829.34L3383.06,2949.94L2778.45,2641.88C2782.17,2634.56 2785.59,2627.17 2788.68,2619.72Z" style={{fill: `${innerRing[2].charAt(2) === "1" || innerRing[2] === "00000000" ? "#DF6A33" : "#FFFFFF"}`, stroke: "#787878", strokeWidth: "11.67px"}}/>
                <path d="M2766.53,2663.18L3233.21,2948.89L3383.05,2949.94L2778.44,2641.88C2774.72,2649.19 2770.74,2656.3 2766.53,2663.18Z" style={{fill: `${innerRing[2].charAt(3) === "1" || innerRing[2] === "00000000" ? "#DF6A33" : "#FFFFFF"}`, stroke: "#787878", strokeWidth: "11.67px"}}/>
                <path d="M2766.39,2663.4L3232.83,2949.51L3301.79,3082.54L2752.82,2683.68C2757.64,2677.04 2762.17,2670.27 2766.39,2663.4Z" style={{fill: `${innerRing[2].charAt(4) === "1" || innerRing[2] === "00000000" ? "#DF6A33" : "#FFFFFF"}`, stroke: "#787878", strokeWidth: "11.67px"}}/>
                <path d="M2737.72,2702.86L3153.96,3058.05L3301.79,3082.54L2752.82,2683.68C2747.99,2690.32 2742.95,2696.72 2737.72,2702.86Z" style={{fill: `${innerRing[2].charAt(5) === "1" || innerRing[2] === "00000000" ? "#DF6A33" : "#FFFFFF"}`, stroke: "#787878", strokeWidth: "11.67px"}}/>
                <path d="M2737.55,2703.06L3153.49,3058.61L3200.79,3200.79L2720.97,2720.97C2726.78,2715.17 2732.3,2709.19 2737.55,2703.06Z" style={{fill: `${innerRing[2].charAt(6) === "1" || innerRing[2] === "00000000" ? "#DF6A33" : "#FFFFFF"}`, stroke: "#787878", strokeWidth: "11.67px"}}/>
                <path d="M2703.06,2737.55L3058.61,3153.49L3200.79,3200.79L2720.97,2720.97C2715.17,2726.78 2709.19,2732.31 2703.06,2737.55Z" style={{fill: `${innerRing[2].charAt(7) === "1" || innerRing[2] === "00000000" ? "#DF6A33" : "#FFFFFF"}`, stroke: "#787878", strokeWidth: "11.67px"}}/>

                <path d="M2702.86,2737.72L3058.05,3153.96L3082.54,3301.79L2683.68,2752.82C2690.32,2747.99 2696.72,2742.95 2702.86,2737.72Z" style={{fill: `${innerRing[2] === "00000000" && innerRing[3] === "00000000" ? "#DF6A33" : "#FFFFFF"}`, stroke: "#787878", strokeWidth: "11.67px"}}/>
                <path d="M2663.4,2766.39L2949.51,3232.82L3082.54,3301.79L2683.68,2752.82C2677.04,2757.64 2670.27,2762.17 2663.4,2766.39Z" style={{fill: `${innerRing[2] === "00000000" && innerRing[3] === "00000000" ? "#DF6A33" : "#FFFFFF"}`, stroke: "#787878", strokeWidth: "11.67px"}}/>
                <path d="M2663.17,2766.53L2948.88,3233.21L2949.93,3383.05L2641.87,2778.44C2649.18,2774.72 2656.29,2770.74 2663.17,2766.53Z" style={{fill: `${innerRing[2] === "00000000" && innerRing[3] === "00000000" ? "#DF6A33" : "#FFFFFF"}`, stroke: "#787878", strokeWidth: "11.67px"}}/>

                <path d="M2619.71,2788.67L2829.33,3294.12L2949.93,3383.05L2641.87,2778.44C2634.55,2782.16 2627.16,2785.58 2619.71,2788.67Z" style={{fill: `${innerRing[3].charAt(1) === "1" || innerRing[3] === "00000000" ? "#DF6A33" : "#FFFFFF"}`, stroke: "#787878", strokeWidth: "11.67px"}}/>
                <path d="M2619.47,2788.78L2828.65,3294.41L2806.26,3442.57L2596.57,2797.21C2604.37,2794.68 2612.01,2791.86 2619.47,2788.78Z" style={{fill: `${innerRing[3].charAt(2) === "1" || innerRing[3] === "00000000" ? "#DF6A33" : "#FFFFFF"}`, stroke: "#787878", strokeWidth: "11.67px"}}/>
                <path d="M2573.08,2803.85L2701.05,3335.87L2806.26,3442.57L2596.57,2797.21C2588.76,2799.75 2580.92,2801.96 2573.08,2803.85Z" style={{fill: `${innerRing[3].charAt(3) === "1" || innerRing[3] === "00000000" ? "#DF6A33" : "#FFFFFF"}`, stroke: "#787878", strokeWidth: "11.67px"}}/>
                <path d="M2572.82,2803.91L2700.33,3336.04L2655.03,3478.87L2548.88,2808.66C2556.99,2807.37 2564.97,2805.79 2572.82,2803.91Z" style={{fill: `${innerRing[3].charAt(4) === "1" || innerRing[3] === "00000000" ? "#DF6A33" : "#FFFFFF"}`, stroke: "#787878", strokeWidth: "11.67px"}}/>
                <path d="M2524.65,2811.54L2567.82,3357.03L2655.04,3478.87L2548.89,2808.66C2540.78,2809.94 2532.69,2810.9 2524.65,2811.54Z" style={{fill: `${innerRing[3].charAt(5) === "1" || innerRing[3] === "00000000" ? "#DF6A33" : "#FFFFFF"}`, stroke: "#787878", strokeWidth: "11.67px"}}/>
                <path d="M2524.39,2811.56L2567.09,3357.09L2500,3491.07L2500,2812.5C2508.21,2812.5 2516.35,2812.18 2524.39,2811.56Z" style={{fill: `${innerRing[3].charAt(6) === "1" || innerRing[3] === "00000000" ? "#DF6A33" : "#FFFFFF"}`, stroke: "#787878", strokeWidth: "11.67px"}}/>
                <path d="M2475.61,2811.56L2432.91,3357.09L2500,3491.07L2500,2812.5C2491.79,2812.5 2483.65,2812.18 2475.61,2811.56Z" style={{fill: `${innerRing[3].charAt(7) === "1" || innerRing[3] === "00000000" ? "#DF6A33" : "#FFFFFF"}`, stroke: "#787878", strokeWidth: "11.67px"}}/>

                <path d="M2475.34,2811.54L2432.17,3357.03L2344.95,3478.87L2451.11,2808.66C2459.21,2809.94 2467.3,2810.9 2475.34,2811.54Z" style={{fill: `${innerRing[3] === "00000000" && innerRing[4] === "00000000" ? "#DF6A33" : "#FFFFFF"}`, stroke: "#787878", strokeWidth: "11.67px"}}/>
                <path d="M2427.17,2803.91L2299.66,3336.04L2344.96,3478.87L2451.11,2808.66C2443,2807.37 2435.01,2805.79 2427.17,2803.91Z" style={{fill: `${innerRing[3] === "00000000" && innerRing[4] === "00000000" ? "#DF6A33" : "#FFFFFF"}`, stroke: "#787878", strokeWidth: "11.67px"}}/>
                <path d="M2426.92,2803.85L2298.95,3335.87L2193.74,3442.57L2403.43,2797.21C2411.24,2799.75 2419.08,2801.96 2426.92,2803.85Z" style={{fill: `${innerRing[3] === "00000000" && innerRing[4] === "00000000" ? "#DF6A33" : "#FFFFFF"}`, stroke: "#787878", strokeWidth: "11.67px"}}/>

                <path d="M2380.53,2788.77L2171.35,3294.4L2193.74,3442.56L2403.43,2797.2C2395.63,2794.66 2387.99,2791.85 2380.53,2788.77Z" style={{fill: `${innerRing[4].charAt(1) === "1" || innerRing[4] === "00000000" ? "#DF6A33" : "#FFFFFF"}`, stroke: "#787878", strokeWidth: "11.67px"}}/>
                <path d="M2380.3,2788.68L2170.68,3294.13L2050.08,3383.06L2358.14,2778.45C2365.46,2782.17 2372.85,2785.59 2380.3,2788.68Z" style={{fill: `${innerRing[4].charAt(2) === "1" || innerRing[4] === "00000000" ? "#DF6A33" : "#FFFFFF"}`, stroke: "#787878", strokeWidth: "11.67px"}}/>
                <path d="M2336.84,2766.53L2051.14,3233.21L2050.08,3383.05L2358.14,2778.44C2350.83,2774.72 2343.72,2770.74 2336.84,2766.53Z" style={{fill: `${innerRing[4].charAt(3) === "1" || innerRing[4] === "00000000" ? "#DF6A33" : "#FFFFFF"}`, stroke: "#787878", strokeWidth: "11.67px"}}/>
                <path d="M2336.59,2766.39L2050.48,3232.83L1917.45,3301.79L2316.31,2752.82C2322.95,2757.64 2329.72,2762.17 2336.59,2766.39Z" style={{fill: `${innerRing[4].charAt(4) === "1" || innerRing[4] === "00000000" ? "#DF6A33" : "#FFFFFF"}`, stroke: "#787878", strokeWidth: "11.67px"}}/>
                <path d="M2297.13,2737.72L1941.94,3153.96L1917.45,3301.79L2316.31,2752.82C2309.67,2747.99 2303.27,2742.95 2297.13,2737.72Z" style={{fill: `${innerRing[4].charAt(5) === "1" || innerRing[4] === "00000000" ? "#DF6A33" : "#FFFFFF"}`, stroke: "#787878", strokeWidth: "11.67px"}}/>
                <path d="M2296.94,2737.55L1941.39,3153.49L1799.21,3200.79L2279.03,2720.97C2284.83,2726.78 2290.81,2732.3 2296.94,2737.55Z" style={{fill: `${innerRing[4].charAt(6) === "1" || innerRing[4] === "00000000" ? "#DF6A33" : "#FFFFFF"}`, stroke: "#787878", strokeWidth: "11.67px"}}/>
                <path d="M2262.45,2703.06L1846.52,3058.61L1799.21,3200.79L2279.03,2720.97C2273.22,2715.17 2267.69,2709.19 2262.45,2703.06Z" style={{fill: `${innerRing[4].charAt(7) === "1" || innerRing[4] === "00000000" ? "#DF6A33" : "#FFFFFF"}`, stroke: "#787878", strokeWidth: "11.67px"}}/>

                <path d="M2262.28,2702.86L1846.04,3058.05L1698.21,3082.54L2247.18,2683.68C2252.01,2690.32 2257.05,2696.72 2262.28,2702.86Z" style={{fill: `${innerRing[4] === "00000000" && innerRing[5] === "00000000" ? "#DF6A33" : "#FFFFFF"}`, stroke: "#787878", strokeWidth: "11.67px"}}/>
                <path d="M2233.61,2663.4L1767.18,2949.51L1698.21,3082.54L2247.18,2683.68C2242.36,2677.04 2237.83,2670.27 2233.61,2663.4Z" style={{fill: `${innerRing[4] === "00000000" && innerRing[5] === "00000000" ? "#DF6A33" : "#FFFFFF"}`, stroke: "#787878", strokeWidth: "11.67px"}}/>
                <path d="M2233.46,2663.17L1766.78,2948.88L1616.94,2949.93L2221.55,2641.87C2225.28,2649.18 2229.25,2656.29 2233.46,2663.17Z" style={{fill: `${innerRing[4] === "00000000" && innerRing[5] === "00000000" ? "#DF6A33" : "#FFFFFF"}`, stroke: "#787878", strokeWidth: "11.67px"}}/>

                <path d="M2211.31,2619.71L1705.86,2829.33L1616.93,2949.93L2221.54,2641.87C2217.82,2634.55 2214.4,2627.16 2211.31,2619.71Z" style={{fill: `${innerRing[5].charAt(1) === "1" || innerRing[5] === "00000000" ? "#DF6A33" : "#FFFFFF"}`, stroke: "#787878", strokeWidth: "11.67px"}}/>
                <path d="M2211.25,2619.47L1705.62,2828.65L1557.46,2806.26L2202.82,2596.57C2205.36,2604.37 2208.17,2612.01 2211.25,2619.47Z" style={{fill: `${innerRing[5].charAt(2) === "1" || innerRing[5] === "00000000" ? "#DF6A33" : "#FFFFFF"}`, stroke: "#787878", strokeWidth: "11.67px"}}/>
                <path d="M2196.17,2573.08L1664.15,2701.05L1557.45,2806.26L2202.81,2596.57C2200.27,2588.76 2198.06,2580.92 2196.17,2573.08Z" style={{fill: `${innerRing[5].charAt(3) === "1" || innerRing[5] === "00000000" ? "#DF6A33" : "#FFFFFF"}`, stroke: "#787878", strokeWidth: "11.67px"}}/>
                <path d="M2196.1,2572.82L1663.97,2700.33L1521.14,2655.03L2191.36,2548.88C2192.64,2556.99 2194.22,2564.97 2196.1,2572.82Z" style={{fill: `${innerRing[5].charAt(4) === "1" || innerRing[5] === "00000000" ? "#DF6A33" : "#FFFFFF"}`, stroke: "#787878", strokeWidth: "11.67px"}}/>
                <path d="M2188.47,2524.65L1642.98,2567.82L1521.14,2655.04L2191.36,2548.89C2190.07,2540.78 2189.11,2532.69 2188.47,2524.65Z" style={{fill: `${innerRing[5].charAt(5) === "1" || innerRing[5] === "00000000" ? "#DF6A33" : "#FFFFFF"}`, stroke: "#787878", strokeWidth: "11.67px"}}/>
                <path d="M2188.44,2524.39L1642.91,2567.09L1508.93,2500L2187.5,2500C2187.5,2508.21 2187.82,2516.35 2188.44,2524.39Z" style={{fill: `${innerRing[5].charAt(6) === "1" || innerRing[5] === "00000000" ? "#DF6A33" : "#FFFFFF"}`, stroke: "#787878", strokeWidth: "11.67px"}}/>
                <path d="M2188.44,2475.61L1642.91,2432.91L1508.93,2500L2187.5,2500C2187.5,2491.79 2187.82,2483.65 2188.44,2475.61Z" style={{fill: `${innerRing[5].charAt(7) === "1" || innerRing[5] === "00000000" ? "#DF6A33" : "#FFFFFF"}`, stroke: "#787878", strokeWidth: "11.67px"}}/>

                <path d="M2188.48,2475.34L1642.99,2432.17L1521.15,2344.95L2191.37,2451.11C2190.08,2459.21 2189.12,2467.3 2188.48,2475.34Z" style={{fill: `${innerRing[5] === "00000000" && innerRing[6] === "00000000" ? "#DF6A33" : "#FFFFFF"}`, stroke: "#787878", strokeWidth: "11.67px"}}/>
                <path d="M2196.11,2427.17L1663.98,2299.66L1521.15,2344.96L2191.37,2451.11C2192.65,2443 2194.24,2435.01 2196.11,2427.17Z" style={{fill: `${innerRing[5] === "00000000" && innerRing[6] === "00000000" ? "#DF6A33" : "#FFFFFF"}`, stroke: "#787878", strokeWidth: "11.67px"}}/>
                <path d="M2196.15,2426.92L1664.13,2298.95L1557.43,2193.74L2202.79,2403.43C2200.25,2411.24 2198.04,2419.08 2196.15,2426.92Z" style={{fill: `${innerRing[5] === "00000000" && innerRing[6] === "00000000" ? "#DF6A33" : "#FFFFFF"}`, stroke: "#787878", strokeWidth: "11.67px"}}/>

                <path d="M2211.23,2380.53L1705.6,2171.35L1557.44,2193.74L2202.8,2403.43C2205.34,2395.63 2208.15,2387.99 2211.23,2380.53Z" style={{fill: `${innerRing[6].charAt(1) === "1" || innerRing[6] === "00000000" ? "#DF6A33" : "#FFFFFF"}`, stroke: "#787878", strokeWidth: "11.67px"}}/>
                <path d="M2211.32,2380.3L1705.87,2170.68L1616.94,2050.08L2221.55,2358.14C2217.83,2365.46 2214.41,2372.85 2211.32,2380.3Z" style={{fill: `${innerRing[6].charAt(2) === "1" || innerRing[6] === "00000000" ? "#DF6A33" : "#FFFFFF"}`, stroke: "#787878", strokeWidth: "11.67px"}}/>
                <path d="M2233.47,2336.84L1766.79,2051.14L1616.95,2050.08L2221.56,2358.14C2225.28,2350.83 2229.26,2343.72 2233.47,2336.84Z" style={{fill: `${innerRing[6].charAt(3) === "1" || innerRing[6] === "00000000" ? "#DF6A33" : "#FFFFFF"}`, stroke: "#787878", strokeWidth: "11.67px"}}/>
                <path d="M2233.61,2336.59L1767.17,2050.48L1698.21,1917.45L2247.18,2316.31C2242.36,2322.95 2237.83,2329.72 2233.61,2336.59Z" style={{fill: `${innerRing[6].charAt(4) === "1" || innerRing[6] === "00000000" ? "#DF6A33" : "#FFFFFF"}`, stroke: "#787878", strokeWidth: "11.67px"}}/>
                <path d="M2262.28,2297.13L1846.04,1941.94L1698.21,1917.45L2247.18,2316.31C2252.01,2309.67 2257.05,2303.27 2262.28,2297.13Z" style={{fill: `${innerRing[6].charAt(5) === "1" || innerRing[6] === "00000000" ? "#DF6A33" : "#FFFFFF"}`, stroke: "#787878", strokeWidth: "11.67px"}}/>
                <path d="M2262.45,2296.94L1846.52,1941.39L1799.21,1799.21L2279.03,2279.03C2273.22,2284.83 2267.7,2290.81 2262.45,2296.94Z" style={{fill: `${innerRing[6].charAt(6) === "1" || innerRing[6] === "00000000" ? "#DF6A33" : "#FFFFFF"}`, stroke: "#787878", strokeWidth: "11.67px"}}/>
                <path d="M2296.94,2262.45L1941.39,1846.52L1799.21,1799.21L2279.03,2279.03C2284.83,2273.22 2290.81,2267.69 2296.94,2262.45Z" style={{fill: `${innerRing[6].charAt(7) === "1" || innerRing[6] === "00000000" ? "#DF6A33" : "#FFFFFF"}`, stroke: "#787878", strokeWidth: "11.67px"}}/>

                <path d="M2297.14,2262.28L1941.95,1846.04L1917.46,1698.21L2316.32,2247.18C2309.68,2252.01 2303.28,2257.05 2297.14,2262.28Z" style={{fill: `${innerRing[6] === "00000000" && innerRing[7] === "00000000" ? "#DF6A33" : "#FFFFFF"}`, stroke: "#787878", strokeWidth: "11.67px"}}/>
                <path d="M2336.6,2233.61L2050.49,1767.18L1917.46,1698.21L2316.32,2247.18C2322.96,2242.36 2329.73,2237.83 2336.6,2233.61Z" style={{fill: `${innerRing[6] === "00000000" && innerRing[7] === "00000000" ? "#DF6A33" : "#FFFFFF"}`, stroke: "#787878", strokeWidth: "11.67px"}}/>
                <path d="M2336.83,2233.46L2051.12,1766.78L2050.07,1616.94L2358.13,2221.55C2350.82,2225.28 2343.71,2229.25 2336.83,2233.46Z" style={{fill: `${innerRing[6] === "00000000" && innerRing[7] === "00000000" ? "#DF6A33" : "#FFFFFF"}`, stroke: "#787878", strokeWidth: "11.67px"}}/>

                <path d="M2380.29,2211.31L2170.67,1705.86L2050.07,1616.93L2358.13,2221.54C2365.45,2217.82 2372.84,2214.4 2380.29,2211.31Z" style={{fill: `${innerRing[7].charAt(1) === "1" || innerRing[7] === "00000000" ? "#DF6A33" : "#FFFFFF"}`, stroke: "#787878", strokeWidth: "11.67px"}}/>
                <path d="M2380.53,2211.25L2171.35,1705.62L2193.74,1557.46L2403.43,2202.82C2395.63,2205.36 2387.99,2208.17 2380.53,2211.25Z" style={{fill: `${innerRing[7].charAt(2) === "1" || innerRing[7] === "00000000" ? "#DF6A33" : "#FFFFFF"}`, stroke: "#787878", strokeWidth: "11.67px"}}/>
                <path d="M2426.92,2196.17L2298.95,1664.15L2193.74,1557.45L2403.43,2202.81C2411.24,2200.27 2419.08,2198.06 2426.92,2196.17Z" style={{fill: `${innerRing[7].charAt(3) === "1" || innerRing[7] === "00000000" ? "#DF6A33" : "#FFFFFF"}`, stroke: "#787878", strokeWidth: "11.67px"}}/>
                <path d="M2427.18,2196.1L2299.67,1663.97L2344.97,1521.14L2451.12,2191.36C2443.01,2192.64 2435.03,2194.22 2427.18,2196.1Z" style={{fill: `${innerRing[7].charAt(4) === "1" || innerRing[7] === "00000000" ? "#DF6A33" : "#FFFFFF"}`, stroke: "#787878", strokeWidth: "11.67px"}}/>
                <path d="M2475.35,2188.47L2432.18,1642.98L2344.96,1521.14L2451.12,2191.36C2459.22,2190.07 2467.31,2189.11 2475.35,2188.47Z" style={{fill: `${innerRing[7].charAt(5) === "1" || innerRing[7] === "00000000" ? "#DF6A33" : "#FFFFFF"}`, stroke: "#787878", strokeWidth: "11.67px"}}/>
                <path d="M2475.61,2188.44L2432.91,1642.91L2500,1508.93L2500,2187.5C2491.79,2187.5 2483.65,2187.82 2475.61,2188.44Z" style={{fill: `${innerRing[7].charAt(6) === "1" || innerRing[7] === "00000000" ? "#DF6A33" : "#FFFFFF"}`, stroke: "#787878", strokeWidth: "11.67px"}}/>
            </g>
        </g>
    </svg>
  );
};

export default PerseveranceParachute;
